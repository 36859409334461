import { 
    Button, 
    Container, 
    Row, 
    Col, 
    Modal,
    Form,
    FloatingLabel,
    Table,
    Pagination
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ModalErro from './ModalErro';
import React, { useEffect } from 'react';
import global from '../global';
import { LuPlusCircle } from "react-icons/lu";

ModalSelecionarVendedor.propTypes = {

    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    setVendedorSelecionado: PropTypes.func.isRequired,
    vendedores: PropTypes.array,
    funcPos: PropTypes.func.isRequired

}

export default function ModalSelecionarVendedor({ showModal, setShowModal, setVendedorSelecionado, vendedores, funcPos}) {

    const [codVendedorDigitado, setCodVendedorDigitado] = React.useState("");
    const [nomeVendedorDigitado, setNomeVendedorDigitado] = React.useState("");
    const [vendedoresModal, setVendedoresModal] = React.useState(vendedores);

    //Modal erro
    const [showModalErro, setShowModalErro] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");

    const [paginaAtual, setPaginaAtual] = React.useState(1);

    console.log("vendedoresModal", vendedoresModal);

    useEffect(() => {

        console.log("teste", nomeVendedorDigitado);

        if(nomeVendedorDigitado == '' && codVendedorDigitado == ''){
            setVendedoresModal(vendedores);
        }else{

            let vendedoresAux = [];
            vendedores.map((item) => {
                console.log(item.idVendedor);
                console.log(codVendedorDigitado);

                if(item.nomeVendedor.toString().includes(nomeVendedorDigitado) && nomeVendedorDigitado != ''){
                    vendedoresAux.push(item);
                }else if(item.idVendedor.toString().includes(codVendedorDigitado) && codVendedorDigitado != ''){
                    vendedoresAux.push(item);
                }
            })

            console.log(vendedoresAux);

            setVendedoresModal(vendedoresAux);
        }

    }, [nomeVendedorDigitado, codVendedorDigitado]);

    return (

        <Modal
        show={showModal}
        onHide={() => setShowModal(false) }
        // backdrop="static"
        // keyboard={false}
        centered
        size='lg'
        >
            
            {/* Modal erro */}
            <ModalErro showModal={showModalErro} setShowModal={ (e) => { setShowModalErro(e) } } msg={errorMsg} permiteFechar={false} />

            <Modal.Header closeButton={true}>
                
                <Modal.Title>
                    <h4>Código do vendedor:</h4> 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>

                    <Row>
                        <Col >
                            
                            <FloatingLabel
                                label="Codigo vendedor: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type={'text'}
                                    value={codVendedorDigitado}
                                    onChange={(e) => {
                                        setCodVendedorDigitado(e.target.value)
                                    }}
                                />
                            </FloatingLabel>
                        
                        </Col>
                        <Col
                        style={{textAlign: "center", justifySelf: "center"}}
                        xs={12}
                        sm={12}
                        md={2}
                        lg={2}
                        xl={2}
                        >
                            <div className="d-grid gap-2">
                                <Button onClick={() => {
                                    let vendedorSelecionado = vendedores.find(item => item.idVendedor == codVendedorDigitado);

                                    console.log("VENDEDOR SELECIONADO MODAL: ", vendedorSelecionado);

                                    if(vendedorSelecionado){
                                        setVendedorSelecionado(vendedorSelecionado);
                                        setShowModal(false);
                                        funcPos(vendedorSelecionado);
                                    }else{
                                        setErrorMsg("Vendedor não encontrado");
                                        setShowModalErro(true);
                                    }   

                                }}>
                                    <LuPlusCircle size={45}/>
                                </Button>
                                
                            </div>
                            {/* <div className="d-grid gap-2">
                                <Button
                                    style={{height: "100%"}}
                                    variant='success'
                                    onClick={() => {


                                    }}
                                >
                                    Selecionar
                                </Button>
                            </div> */}
                        </Col>
                    </Row>
                    
                </Container>
                
            </Modal.Body>
        </Modal>

    );

}
