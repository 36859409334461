export default {

    //URL_BASE: "https://caixa.ht01teste.com.br",
    //URL_BASE: "https://localhost:7041",

    //URL PRODUÇÃO
    URL_BASE: "https://api.caixa.bio",
    
    //caminhos api
    CAMINHO_LISTARFORMASPAGAMENTOS: "/ListarFormasDePagamentos",
    CAMINHO_LOGIN: '/login',
    CAMINHO_RETORNO_NUMCUPOM: '/Retornar_NumCupom',
    CAMINHO_BUSCARPRODUTOS: '/BuscarProdutos',
    CAMINHO_ADICIONAVENDAITEM: '/adicionaVendaItem',
    CAMINHO_ATUALIZARFORMAPAGAMENTOCUPOM: '/atualizaFormaPagamentoCupom',
    CAMINHO_CANCELARITEMCUPOM: '/cancelarItemCupom',
    CAMINHO_CANCELARCUPOM: '/cancelarCupom',
    CAMINHO_EMITIRNFCe: '/EmitirNFCe',
    CAMINHO_ENVIAREMAILNAOFISCAL: '/EnviarEmailCupomNaoFiscal',
    CAMINHO_CONSULTACLIENTE: '/BuscaCliente',
    CAMINHO_CADASTRAEDITACLIENTE: '/CadastraClientes',
    CAMINHO_CADASTRAPARCELASPAGAMENTOFIDELIDADE: '/CadastraParcelasPagamentosFidelidade',
    CAMINHO_ABERTURACAIXA: "/aberturaCaixa",
    CAMINHO_FECHAMENTOCAIXA: "/fechamentoCaixa",
    CAMINHO_BUSCAULTIMAABERTURACAIXA: "/buscaUltimaAberturaCaixa",
    CAMINHO_CONSULTACEP: "/ConsultaCep",
    CAMINHO_CARREGAESTADOS: "/CarregaEstados",
    CAMINHO_CARREGACIDADES: "/CarregaCidades",
    CAMINHO_LISTACUPONSINPRESSAO: "/ListaCupomsParaImpressoes",
    CAMINHO_LISTANFCES: "/BuscaNFCe",
    CAMINHO_REEMITIR: "/ReemitirNFCe",
    CAMINHO_BAIXARNFCE: "/BaixarPdfNfce",
    CAMINHO_ALTERARSENHA: "/AlterarSenhaOperador",
    CAMINHO_ENVIAREMAILNFCE: "/EnviarEmailNFCe",
    CAMINHO_MAC_ADDRESS: "/GetMacAdress",
    CAMINHO_CANCELARNFCE: "/CancelarNFCe",

    //signalR
    CAMINHO_SIGNALr: "/SignalR",

    //Global propriedades
    USER_WS: "teste1",
    PASS_WS: "teste1",

    //valores padroes
    VALOR_POR_PAGINA: 15

};