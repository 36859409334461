import React, { useEffect, useState } from 'react'
import * as SignalR from '@microsoft/signalr'
import { 
    Button, 
    Form, 
    Container, 
    Row, 
    Col, 
    Navbar,
    Offcanvas,
    Nav,
    FloatingLabel,
    Modal,
    Spinner,
    Pagination
} from 'react-bootstrap';
import * as Sentry from "@sentry/react";
import { CiSearch } from "react-icons/ci";
import { LuPlusCircle } from "react-icons/lu";
import { IoMdClose } from "react-icons/io";
import { BiError } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { FaClipboardUser } from "react-icons/fa6";
import { AiOutlineCheck } from "react-icons/ai";
import printJS from "print-js";
import axios from 'axios';
import { FaSearch, FaCamera  } from 'react-icons/fa';

import global from '../global';
import ReactToPrint from 'react-to-print';
import InputMask from 'react-input-mask';
import ModalLoad from '../components/ModalLoad';
import ModalSucesso from '../components/ModalSucesso';
import ModalErro from '../components/ModalErro';
import ModalEmail from '../components/ModalEmail';
import ModalDeslogar from '../components/ModalDeslogar';
import ModalAlterarSenhaOperador from '../components/ModalAlterarSenhaOperador';
import ModalAdminSenha from '../components/ModalAdminSenha';
import ModalConfimacao from '../components/ModalConfimacao';
import { MobileView } from 'react-device-detect';
import ScannerOverlay from '../components/ScannerOverlay';
import ModalSelecionarVendedor from '../components/ModalSelecionarVendedor';

export default function Home() {

    //resgatando informação do usuario
    //console.log("INFO usuario: ", sessionStorage.getItem("informacoesUsuario"));
    if(JSON.parse(sessionStorage.getItem("informacoesUsuario")) == null || JSON.parse(sessionStorage.getItem("informacoesUsuario")) == undefined  ){
        window.location.href = "/login";
    }

    //states

    //objetos internos
    const [user] = React.useState( JSON.parse(sessionStorage.getItem("informacoesUsuario")) );
    const [vendedores] = React.useState(user.operador.vendedores || []);
    console.log(user);
    const [produtoSelecionado, setProdutoSelecionado] = React.useState(null);
    const [total, setTotal] = React.useState(0.00);
    const [troco, setTroco] = React.useState(0.00);
    const [desconto, setDesconto] = React.useState(0.00);
    const [valorPagoTotalDinheiro, setValorPagoTotalDinheiro] = React.useState(0.00);
    const [produtos, setProdutos] = React.useState([]);
    const [cupom, setCupom] = React.useState(null);
    const [sequenciaItem, setSequenciaItem] = React.useState(1);
    const [formasDePagamentosSelecionadas, setFormasDePagamentosSelecionadas] = React.useState([]);
    const [formasPagSelecDinheiros, setFormasPagSelecDinheiros] = React.useState([]);
    const [formasPagSelecFidelidade, setFormasPagSelecFidelidade] = React.useState([]);
    const [nfceBase64, setNfceBase64] = React.useState("");
    const [controleAberturaFechamentoCaixa, setControleAberturaFechamentoCaixa ] = React.useState(false);
    const [codAbertura, setCodAbertura] = React.useState(0);
    //const [controleDisabledPreco, setControleDisabledPreco] = React.useState(true);
    const [controleCupomJaIniciado, setControleCupomJaIniciado] = React.useState(false);
    const [vendedorSelecionado, setVendedorSelecionado] = React.useState(null);

    //info user
    const [ipUser, setIpUser] = React.useState("");
    const [macAddresses, setMacAddresses] = React.useState();

    //controles
    const [codProd, setCodProd] = React.useState("");
    const [nomeProdutoSelecionado, setNomeProdutoSelecionado] = React.useState("");
    const [qtdSelecionada, setQtdSelecionada] = React.useState(1);
    const [preco, setPreco] = React.useState(0);
    
    //modal erro
    const [errorMsg, setErrorMsg] = React.useState("");

    //modal Sucess
    const [showModalSucess, setShowMOdalSucess] = React.useState(false);
    const [ModalSucessMsg, setModalSucessMsg] = React.useState("");

    //modal forma de pagamento
    const [showModalFormaDePagamento, setShowModalFormaDePagamento] = React.useState(false);

    //modalCupomFiscalOuNãoFical
    const [showModalCupomFicalOuNaoFiscal, setShowModalCupomFicalOuNaoFiscal] = React.useState(false);
    const [loadNfce, setLodalNfce] = React.useState(false);

    //modal emailCF
    const [showModalEmail, setShowModalEmail] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [numWpp, setNumWpp] = React.useState("");

    //Modal Erro NFCe
    const [showModalErroNFCe, setShowModalErroNFCe] = React.useState(false);

    //modal cliente
    const [showModalCliente, setShowModalCliente] = React.useState(false);

    //modal nome cliente
    const [showModalNomeCliente, setShowModalNomeCliente] = React.useState(false);

    //modal deslogar
    const [showModalDeslogar, setShowModalDeslogar] = React.useState(false);

    //modal cpf/cnpj
    const [showModalCpfCnpj, setShowModalCpfCnpj] = React.useState(false);
    const [cpfcnpjCliente, setCpfCnpjCliente] = React.useState("");

    //modal Load
    const [showModalLoad, setShowModalLoad] = React.useState(false);

    //modal erro
    const [showModalErro, setShowModalErro]= React.useState(false);
    const [naoPermiteFecharModalErro, setnaoPermiteFecharModalErro] = React.useState(false);

    //modal cliente
    const [cpfcnpjClientePesquisaModal, setCpfcnpjClientePesquisaModal] = React.useState("");
    const [idClienteFidelidade, setIdClienteFidelidade] = React.useState(null);
    const [nomeClienteFidelidade, setNomeClienteFidelidade] = React.useState("");
    const [emailClienteFidelidade, setEmailClienteFidelidade] = React.useState("");
    const [celularClienteFidelidade, setcelularClienteFidelidade] = React.useState("");
    const [nascimentoClienteFidelidade, setNascimentoClienteFidelidade] = React.useState("");
    const [cepClienteFidelidade, setCepClienteFidelidade] = React.useState("");
    const [UfClienteFidelidade, setUfClienteFidelidade] = React.useState("");
    const [enderecoClienteFidelidade, setEnderecoClienteFidelidade] = React.useState("");
    const [numeroClienteFidelidade, setNumeroClienteFidelidade] = React.useState("");
    const [bairroClienteFidelidade, setBairroClienteFidelidade] = React.useState("");
    const [cidadeClienteFidelidade, setCidadeClienteFidelidade] = React.useState("");

    const [estadosClientesFidelidade, setEstadosClientesFidelidade] = React.useState([]);
    const [cidadesClientesFidelidade, setCidadesClientesFidelidade] = React.useState([]);

    //modal busca nome cliente 
    const [nomeClienteBusca, setNomeClienteBusca] = React.useState("");
    const [clientesBuscaClientesNome, setClientesBuscaClientesNome] = React.useState([]);
    
    //modal pagamento fidelidade
    const [infoFormaPagamentoFidelidade, setInfoFormaPagamentoFidelidade] = React.useState(null);
    const [showModalPagamentoFidelidade, setShowModalPagamentoFidelidade] = React.useState(false);
    const [qtdParcelasFidelidade, setQtdParcelasFidelidade] = React.useState(1);
    
    //modal adicionar produtos
    const [descricaoPesquisa, setDescricaoPesquisa] = React.useState("");
    const [produtosTodosPesquisa, setProdutosTodosPesquisa] = React.useState([]);

    //modal abertura caixa
    const [showModalAberturaCaixa, setShowModalAberturaCaixa] = React.useState(false);
    const [suprimentoTroco, setSuprimentoTroco] = React.useState(0);

    //modal confirmar ação
    const [showModalConfirmarAcao, setShowModalConfirmarAcao] = React.useState(false);
    const [msgModalConfirmarAcao, setMsgModalConfirmarAcao] = React.useState("");
    const [funcModalConfirmacao, setFuncModalConfirmacao] = React.useState( () => () => {} );

    //modal alterar senha
    const [showModalAlterarSenha, setShowModalAlterarSenha] = React.useState(false);

    //modal admin senha
    const [showModalAdminSenha, setShowModalAdminSenha] = React.useState(false);
    const [funcModalAdminSenha, setFuncModalAdminSenha] = React.useState( () => () => {} );

    //modal sucesso cupom fiscal
    const [showModalSucessoCupomFiscal, setShowModalSucessoCupomFiscal] = React.useState(false);

    //modalSucessFinal
    const [showModalSucessFinal, setShowModalSucessFinal] = React.useState(false);
    const [msgSucessoFinal, setMsgSucessoFinal] = React.useState("");

    //modal selecionar produtos
    const [showModalAdicionarProdutos, setShowModalAdicionarProdutos] = React.useState(false);
    const [pagina, setPagina] = React.useState(0);
    const [numTotalRegistro, setNumTotalRegistro] = React.useState(0);

    //modal selecionar vendedor
    const [showModalSelecionarVendedor, setShowModalSelecionarVendedor] = React.useState(false);
    const [funcPosModalSelecionarVendedor, setFuncPosModalSelecionarVendedor] = useState( () => () => {} );

    //leitura do codigo de barra 
    const [isScanning, setIsScanning] = React.useState(false);
    const [barcode1, setBarcode] = React.useState(null);
    const [funcPosLeitor, setFuncPosLeitor] = React.useState( () => () => {} );

    //ref
    var componentRef = React.useRef();


    
    const renderFormasDePagamentoDinheiro = () => {

        let totalDinheiro = 0;

        formasPagSelecDinheiros.forEach((item) => {
            
            totalDinheiro += parseFloat(item[1]);

        });

        return <Container >

        <Row>
            <Col
            
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            
            >
            
                <h6>{formasPagSelecDinheiros[0][0].formaPagamento}</h6>

            </Col>
        </Row>

        <Row style={{justifyContent: "left", justifyItems: "left"}}>

            <Form.Control 
                type="hidden"
                id={formasPagSelecDinheiros[0][0].formaPagamento + "idFormaPagamento"}
                defaultValue={formasPagSelecDinheiros[0][0].idFormaPagamento}
            />

            <Form.Check
                inline
                name={"group" + formasPagSelecDinheiros[0][0].formaPagamento}
                type={'hidden'}
                id={"check" + formasPagSelecDinheiros[0][0].formaPagamento}
                className="mb-3"
                disabled={true}
                defaultValue={true}
                checked={true}
            />
            <Col
            xs={4}
            sm={4}
            md={5}
            lg={5}
            xl={5}
            style={{display: "block"}}
            >

                <FloatingLabel
                controlId={"valorPago" + formasPagSelecDinheiros[0][0].formaPagamento}
                label="Valor: "
                className="mb-3"
                >
                    <Form.Control 
                        className="mb-3"
                        type="number" step="0.01"
                        defaultValue={parseFloat(totalDinheiro).toFixed(2)}
                        disabled={true}
                    />
                </FloatingLabel>

            </Col>
            <Col
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            style={{display: "block"}}
            >

            </Col>

            <Col
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            style={{textAlign: "center", justifyContent: 'flex-end', justifySelf: "center", justifyItems: 'end'}}
            >

                <div className="d-grid gap-2">
                    <Button
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    variant="danger"
                    onClick={() => {

                        if(formasDePagamentosSelecionadas.length >= 1){

                            let novoArray = [];

                            formasDePagamentosSelecionadas.forEach((element) => {
                            
                                if(element[0].formaPagamento != "DINHEIRO"){
                                    novoArray.push(element);
                                }

                            });

                            setFormasDePagamentosSelecionadas(novoArray);
                            setFormasPagSelecDinheiros([]);

                        }else{

                            setFormasDePagamentosSelecionadas([]);
                            setFormasPagSelecDinheiros([]);

                        }


                    }}
                    style={{padding:13}}
                    >
                        <MdDelete style={{color: "black"}} size={30}/>
                    </Button>
                </div>

            </Col>

        </Row>

        <Row>
            <hr/>
        </Row>

        </Container>

    }
    const buscaCupom = () => {

        //func buscar produtos
        const funcResgatarProdutos = async (tempVendasItens) => {

            try{

                console.log("Temp Itens Vendas", tempVendasItens);
                setShowModalLoad(true);
    
                if(tempVendasItens.length >= 1){
                    //console.log("Temp Itens Vendas DOC", tempVendasItens[0].documentoCliente);
                    //console.log("Temp Itens Vendas DOC", tempVendasItens[0].documentoCliente);
                    setCpfCnpjCliente(tempVendasItens[0].documentoCliente);
    
                    //implementar recuperação od cliente fidelidade
                    if(tempVendasItens[0].documentoClienteFidelidade != null && tempVendasItens[0].documentoClienteFidelidade != ""){
                        buscaClientes(tempVendasItens[0].documentoClienteFidelidade);
                        setCpfcnpjClientePesquisaModal(tempVendasItens[0].documentoClienteFidelidade);
                    }

                }

                let arrayTemp = [];
                let sequencia = 1;

                for (let i = 0; i < tempVendasItens.length; i++) {

                    let item = tempVendasItens[i];
                    
                    console.log('PAUZINHO ITEM DATAVENDA', item);
    
                    setShowModalLoad(true);

                    //cupom foi iniciado com sucesso
                    setControleCupomJaIniciado(true);
    
                    if(item.excluido == 0){
    
                        await axios.post(global.URL_BASE + global.CAMINHO_BUSCARPRODUTOS, {
    
                            idOrganizacao: user.operador.idOrganizacao,
                            idFilial: user.operador.idFilial,
                            numeroCaixa: user.operador.numCaixa,
                            userWS: global.USER_WS,
                            passWS: global.PASS_WS,
                            nomeEan: '',
                            ean: item.ean,
                            
                        })
                        .then((response) => {
    
                            //console.log(' Result Contrução', response.data);
    
                            let resultData = response.data;
                
                            //console.log('Produto Result Contrução', resultData);
                
                            if(resultData.code == 0){

                                console.log("PAUZINHO", resultData);
                
                                if(resultData.content.numProdutosTotal >= 1){
    
                                    for (var i = 0; i < resultData.content.produtos.length; i++) {
                                        if ( item.ean == resultData.content.produtos[i].codigoBarras) {
                                            let valoresprecos = resultData.content.produtos[0].precoProduto.split(',');
                                            resultData.content.precoProduto = valoresprecos[0] + '.' + valoresprecos[1];
    
                                            // let produtosTemp = produtos.slice();
                                            // produtosTemp.push({
                                            //     produto: resultData.content.produtos[0],
                                            //     qtd: parseInt(item.qtd),
                                            //     preco: parseFloat(item.preco),
                                            //     sequencia: parseInt(item.sequenciaItem),
                                            //     dataVenda: new Date(item.dataRegistro) 
                                            // });
                                            // setProdutos(produtosTemp);

                                            arrayTemp.push({
                                                produto: resultData.content.produtos[0],
                                                qtd: parseInt(item.qtd),
                                                preco: parseFloat(item.preco.toString().replaceAll(",", ".")),
                                                sequencia: parseInt(item.sequenciaItem),
                                                dataVenda: new Date(item.dataRegistro) 
                                            });
                
                                            if(parseInt(item.sequenciaItem) + 1 > sequenciaItem){
    
                                                //setSequenciaItem(parseInt(item.sequenciaItem) + 1);
                                                sequencia = parseInt(item.sequenciaItem) + 1;
                                            }
    
                                            break;
                                        }
                                    }
            
                                }else{
    
                                    setShowModalErro(true);
                                    setErrorMsg("Ocorreu algum erro ao resgatar o cupom, por favor, contate o suporte!");
                                    setnaoPermiteFecharModalErro(true);
    
                                }
            
                            }else{
        
                                setShowModalErro(true);
                                setErrorMsg("Ocorreu algum erro ao resgatar o cupom, por favor, contate o suporte!");
                                setnaoPermiteFecharModalErro(true);
        
                            }
                            
                        })
                        .catch((error) => {
    
                            Sentry.captureException(error);
    
                            console.log('PAUZINHO Result Contrução error', error);
    
                            setShowModalErro(true);
                            setErrorMsg("Ocorreu algum erro ao resgatar o cupom, por favor, contate o suporte!");
                            setnaoPermiteFecharModalErro(true);
    
                        })
                        .finally(() => setShowModalLoad(false));
    
                    }else{
    
                        if(parseInt(item.sequenciaItem) + 1 > sequenciaItem){
    
                            //setSequenciaItem(parseInt(item.sequenciaItem) + 1);
                            sequencia = parseInt(item.sequenciaItem) + 1;

                        }
    
                    }

                }

                setProdutos(arrayTemp);
                setSequenciaItem(sequencia);
    
                setShowModalLoad(false);
    
            }catch(error){
    
                Sentry.captureException(error);
    
                setShowModalErro(true);
                setErrorMsg("Ocorreu algum erro ao resgatar o cupom, por favor, contate o suporte!");
                setnaoPermiteFecharModalErro(true);
                setShowModalLoad(false);
    
            }

        }

        //busca ultimo cupom
        try{

            setShowModalLoad(true);

            axios.post(global.URL_BASE + global.CAMINHO_RETORNO_NUMCUPOM, {
                idorganizacao: user.operador.idOrganizacao.toString(),
                idFilical: user.operador.idFilial.toString(),
                numCaixa: user.operador.numCaixa,
                userWS: global.USER_WS,
                passWS: global.PASS_WS

            })
            .then((response) => {

                let resultData = response.data;

                if(resultData.code === 0){

                    if(Array.isArray(resultData.content)){

                        //console.log('Objs retornados api recuperar produtos', resultData.content);

                        //construção dos itens do cupom
                        setCupom(resultData.content[0].numCupom);
                        funcResgatarProdutos(resultData.content);
                        
                    }else{

                        setCupom(resultData.content);

                    }                    

                }else{

                    setShowModalErro(true);
                    setErrorMsg(resultData.msg);
                    setnaoPermiteFecharModalErro(true);

                }

            })
            .catch((error) => {

                Sentry.captureException(error);

                setShowModalErro(true);
                setErrorMsg(error.message);
                setnaoPermiteFecharModalErro(true);

            })
            .finally(() => setShowModalLoad(false));

        }catch(ex){

            Sentry.captureException(ex);

            setShowModalErro(true);
            setErrorMsg("Ocorreu um erro ao resgatar o cupom, por favor, contate o suporte: " +  ex.message);
            setnaoPermiteFecharModalErro(true);
            setShowModalLoad(false);

        }

        //busca abertura de caixa
        try{

            let data = {
    
                idOrganizacao: user.operador.idOrganizacao,
                idFilial: user.operador.idFilial,
                idOperador: user.operador.idOperador,

            };
            
            axios.post(global.URL_BASE + global.CAMINHO_BUSCAULTIMAABERTURACAIXA, data)
            .then((response) => {

                let resultData = response.data;

                if(resultData.code == 0){
                    if(resultData.content != 0 && resultData.content != undefined && resultData.content != null){
                        setCodAbertura(resultData.content);
                        setControleAberturaFechamentoCaixa(true);
                    }
                }else{

                    setShowModalErro(true);
                    setErrorMsg(resultData.msg);
                    setnaoPermiteFecharModalErro(true);

                }
                
            })
            .catch((error) => {
                Sentry.captureException(error);

                setShowModalErro(true);
                setErrorMsg("Ocorreu um erro ao resgatar a ultima abertura de caixa, por favor, contate o suporte: " +  error.message);
                setnaoPermiteFecharModalErro(true);

            })
            .finally(() => {
                setShowModalLoad(false);
            });

        }catch(ex){

            Sentry.captureException(ex);

            setShowModalErro(true);
            setErrorMsg("Ocorreu um erro ao resgatar a ultima abertura de caixa, por favor, contate o suporte: " +  ex.message);
            setnaoPermiteFecharModalErro(true);

        }

    }

    const carregarEstados = async () => {

        //carregar estados
        try{

            axios.post(global.URL_BASE + global.CAMINHO_CARREGAESTADOS, {})
            .then((response) => {

                let resultData = response.data;
                if(resultData.code === 0){

                    let estados = JSON.parse(resultData.content);  
                    setEstadosClientesFidelidade(estados);

                }else{

                    setShowModalErro(true);
                    setErrorMsg(resultData.msg);

                }
                
            })
            .catch((error) => {
                Sentry.captureException(error);

                setShowModalErro(true);
                setErrorMsg("Ocorreu um erro ao carregar os estados, por favor, contate o suporte: " +  error.message);

            });

        }catch(ex){
            Sentry.captureException(ex);

            setShowModalErro(true);
            setErrorMsg("Ocorreu um erro ao carregar os estados, por favor, contate o suporte: " +  ex.msg);

        }

    }
    const validaCpfCnpj = (cpfCnpj) => {
    
        // Remover caracteres especiais
        cpfCnpj = cpfCnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '');
    
        // Validação de CPF (11 dígitos)
        if (cpfCnpj.length === 11) {
            let sum;
            let remainder;
            sum = 0;
    
            if (cpfCnpj === "00000000000") return false;
    
            for (let i = 1; i <= 9; i++) {
                sum += parseInt(cpfCnpj.substring(i - 1, i)) * (11 - i);
            }
            remainder = (sum * 10) % 11;
    
            if (remainder === 10 || remainder === 11) remainder = 0;
            if (remainder !== parseInt(cpfCnpj.substring(9, 10))) return false;
    
            sum = 0;
            for (let i = 1; i <= 10; i++) {
                sum += parseInt(cpfCnpj.substring(i - 1, i)) * (12 - i);
            }
            remainder = (sum * 10) % 11;
    
            if (remainder === 10 || remainder === 11) remainder = 0;
            if (remainder !== parseInt(cpfCnpj.substring(10, 11))) return false;
    
            return true;
        }
    
        // Validação de CNPJ (14 dígitos)
        if (cpfCnpj.length === 14) {
            var b = [ 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2 ]
            var c = String(cpfCnpj).replace(/[^\d]/g, '')
            
            if(c.length !== 14)
                return false

            if(/0{14}/.test(c))
                return false

            for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
            if(c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
                return false

            for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
            if(c[13] != (((n %= 11) < 2) ? 0 : 11 - n))
                return false

            return true
        }
    
        // Se não for nem CPF nem CNPJ
        return false;
    };

    const buscaClientes = async (cpfCnpjParam) => {

        setShowModalLoad(true);

        let data = {
            
            idOrganizacao: user.operador.idOrganizacao,
            idFilial: user.operador.idFilial,
            numeroCaixa: user.operador.numCaixa,
            userWS: global.USER_WS,
            passWS: global.PASS_WS,
            cpf_cnpj: cpfCnpjParam
                
        };

        await axios.post(global.URL_BASE + global.CAMINHO_CONSULTACLIENTE, data)
        .then((response) => {

            let resultData = response.data;

            if(resultData.code != 0){

                setErrorMsg(resultData.msg);
                setShowModalErro(true);

                setIdClienteFidelidade(null);
                setNomeClienteFidelidade("");
                setEmailClienteFidelidade("");
                setcelularClienteFidelidade("");
                setNascimentoClienteFidelidade("");
                setCepClienteFidelidade("");
                setUfClienteFidelidade("");
                setEnderecoClienteFidelidade("");
                setNumeroClienteFidelidade("");
                setBairroClienteFidelidade("");
                setCidadeClienteFidelidade("");

            }else{
                    
                let cliente = resultData.content.clientePDV[0];

                setUfClienteFidelidade(cliente.idUf);
                setIdClienteFidelidade(cliente.idCliente);
                setNomeClienteFidelidade(cliente.nomeCliente);
                setEmailClienteFidelidade(cliente.email);
                setcelularClienteFidelidade(cliente.celular);
                setNascimentoClienteFidelidade(cliente.dataNascimento);
                setCepClienteFidelidade(cliente.enderecoCEP);
                setEnderecoClienteFidelidade(cliente.endereco);
                setNumeroClienteFidelidade(cliente.enderecoNumero);
                setBairroClienteFidelidade(cliente.enderecoBairro);
                setCidadeClienteFidelidade(cliente.idCidadeIBGE);

                
            }
            
        })
        .catch((error) => {
            Sentry.captureException(error);

            setErrorMsg("Ocorreu algum erro ao consultar o cliente. Tente novamente!");
            setShowModalErro(true);
            
        })
        .finally(() => {
            setShowModalLoad(false);
        });

    }

    const getData = async () => {
        axios.get("https://api.ipify.org/?format=json")
        .then((response) => {
            setIpUser(response.data.ip);
        })
        .catch((error) => {
            setErrorMsg("Ocorreu algum erro ao buscar o seu IP: " + error.message);
            setShowModalErro(true);
        });

        axios.get(global.URL_BASE + global.CAMINHO_MAC_ADDRESS)
        .then((response) => {
            if(response.data.code == 0){
                setMacAddresses(response.data.content);
            }else{
                setErrorMsg(response.data.msg);
                setShowModalErro(true);
            }
            
        })
        .catch((error) => {
            setErrorMsg("Ocorreu algum erro ao buscar o MAC Address: " + error.message);
            setShowModalErro(true);
            console.error('Error fetching MAC addresses:', error.message);
        });

    };
    
    const buscaProdutosPorDescricao = () => {
        //busca produtos
        if(descricaoPesquisa != null && descricaoPesquisa != ""){
            setShowModalLoad(true);

            axios.post(global.URL_BASE + global.CAMINHO_BUSCARPRODUTOS, {
    
                idOrganizacao: user.operador.idOrganizacao,
                idFilial: user.operador.idFilial,
                numeroCaixa: user.operador.numCaixa,
                userWS: global.USER_WS,
                passWS: global.PASS_WS,
                nomeEan: descricaoPesquisa,
                pagina: pagina
    
            })
            .then((response) => {
    
                let resultData = response.data;
    
                if(resultData.code == 0){
    
                    resultData.content.produtos.map((item) => {
                        
                        let valoresprecos = item.precoProduto.split(',');
                        item.precoProduto = valoresprecos[0] + '.' + valoresprecos[1];
    
                    });
    
                    setProdutosTodosPesquisa(resultData.content.produtos);
                    setNumTotalRegistro(resultData.content.numProdutosTotal);
    
                }else{
    
                    setProdutosTodosPesquisa([]);
                    setNumTotalRegistro(0);
                    
                }
                
            })
            .catch((error) => {
                Sentry.captureException(error);
    
                setShowModalErro(true);
                setErrorMsg(error.message);
    
            })
            .finally( () => {
                setShowModalLoad(false);
            });
    
        }
    }

    const handleBuscarProdutoCod = () => {

        if(codProd == "" || codProd == null || codProd == undefined){
            setErrorMsg("Digite um codigo ean para realizar a busca");
            setShowModalErro(true);
            return;
        }
        
        setShowModalLoad(true);

        axios.post(global.URL_BASE + global.CAMINHO_BUSCARPRODUTOS, {

            idOrganizacao: user.operador.idOrganizacao,
            idFilial: user.operador.idFilial,
            numeroCaixa: user.operador.numCaixa,
            userWS: global.USER_WS,
            passWS: global.PASS_WS,
            ean: codProd

        })
        .then((response) => {

            let resultData = response.data;

            //('Produto Result', resultData);

            if(resultData.code == 0){

                if(resultData.content.produtos.length >= 1){

                    let produto = resultData.content.produtos[0];
                    
                    let valoresprecos = produto.precoProduto.split(',');
                    produto.precoProduto = valoresprecos[0] + '.' + valoresprecos[1];
                    
                    //selecionar produto
                    setProdutoSelecionado(produto);
                    setNomeProdutoSelecionado(produto.nomeProduto);
                    setPreco(produto.precoProduto);
                    setCodProd(produto.codigoBarras);
                    setShowModalAdicionarProdutos(false);

                }

            }
            
        })
        .catch((error) => {
            Sentry.captureException(error);

            setErrorMsg("Ocorreu um erro ao tentar realizar a busca, por favor, contate o suporte: " + error.message);
            setShowModalErro(true);

        })
        .finally(() => {
            setShowModalLoad(false);
        });
    }
    const handleBuscarProdutosPorNome = () => {
        if(descricaoPesquisa == "" || descricaoPesquisa == undefined || descricaoPesquisa == null){
            setShowModalErro(true);
            setErrorMsg("O campo de pesquisa não pode estar vazio!");
            setShowModalLoad(false);
            return;
        }

        buscaProdutosPorDescricao();
        setPagina(1);
    }

    //carrega informações do usuario
    useEffect(() => {

        const funcConectarSignalR = async () => {
            if(user === null || user === undefined){
                window.location.href = "/login";
            }else{
                if(user.autorizado === false){
                    window.location.href = "/login";
                }else{

                    let connection = new SignalR.HubConnectionBuilder()
                    .withUrl(global.URL_BASE + global.CAMINHO_SIGNALr)
                    .build();
            
                    connection.start();
            
                    connection.on("ORG" + user.operador.idOrganizacao + "FIL" + user.operador.idFilial + "CX" + user.operador.numCaixa, (data) => {
            
                        //console.log("Recebido do SignalR: ", data); 
            
                        if(data == "LogOut"){
            
                            sessionStorage.removeItem("informacoesUsuario");
            
                            window.location.href = "/login";
            
                        }
            
                    });
                }
            }
        }

        funcConectarSignalR();

        //carrega informaçoes iniciais (mudar para o effecr quando tive user)
        buscaCupom();
        carregarEstados();

    }, [user]);

    useEffect(() => {

        //calculo total
        let totalSacola = 0;
        produtos.forEach(element => {
            
            totalSacola += (parseFloat(element.qtd) * parseFloat(element.preco));

        });
        
        //calculo desconto atacadão
        let arrayProdutosQuantTotais = [];

        produtos.forEach(elementProdutosSacolas => {
            
            let encontrado = false;

            arrayProdutosQuantTotais.forEach((item) => {
               
                if(item.produto.codigoBarras === elementProdutosSacolas.produto.codigoBarras){
                    item.qtd += elementProdutosSacolas.qtd; 
                    encontrado = true;
                }

            });

            if(!encontrado){

                arrayProdutosQuantTotais.push({
                    produto: elementProdutosSacolas.produto,
                    qtd: elementProdutosSacolas.qtd,
                });

            }

        });

        let descontoCalculo = 0;

        arrayProdutosQuantTotais.forEach(element => {
           
            let produto = element.produto;
            let quant = element.qtd;

            if(parseFloat(produto.qtdMinimaAtacado) > 0 ){

                //revisar esta logica com o João - !revisado!
                if(quant >= parseFloat(produto.qtdMinimaAtacado)){

                    descontoCalculo += quant * (parseFloat(produto.precoProduto) - parseFloat(produto.precoAtacado));

                }

            }

        });

        setTotal(parseFloat((totalSacola - descontoCalculo).toFixed(2)));
        setDesconto(descontoCalculo);

    }, [produtos, formasDePagamentosSelecionadas, formasPagSelecDinheiros]);

    useEffect(() => {

        //calculo troco 
        let valorTotalPago = 0;
        formasDePagamentosSelecionadas.forEach(element => {
            
            valorTotalPago += parseFloat(element[1]);

        });

        formasPagSelecDinheiros.forEach(element => {
            
            valorTotalPago += parseFloat(element[1]);

        });

        formasPagSelecFidelidade.forEach(element => {
            
            valorTotalPago += parseFloat(element[1]);

        })

        // console.log("valorTotal: ", valorTotalPago);
        // console.log("formasPagSelecFidelidade: ", formasPagSelecFidelidade);
        // console.log("formasPagSelecDinheiros: ", formasPagSelecDinheiros);
        // console.log("formasDePagamentosSelecionadas: ", formasDePagamentosSelecionadas);

        setTroco(parseFloat((valorTotalPago - total).toFixed(2)));

    }, [total, desconto, formasDePagamentosSelecionadas, formasPagSelecDinheiros, formasPagSelecFidelidade]);
    

    // const calculaTotalTodosItens = () => {

    //     let total = 0;
    //     produtos.forEach((item) => {
    //        total += parseFloat(item.preco * item.qtd); 
    //     });

    //     return total;

    // }

    useEffect(() => {

        if(infoFormaPagamentoFidelidade != undefined && infoFormaPagamentoFidelidade != null){

            let quantidadeParcelas = qtdParcelasFidelidade;
            let valorParcela = parseFloat((infoFormaPagamentoFidelidade.valor / quantidadeParcelas).toFixed(2));

            let arrayTemp = [];
            for (let i = 1; i <= quantidadeParcelas; i++) {
                let dataDeVencimento = new Date().setDate(new Date().getDate() + (30 * i) );
                arrayTemp.push(
                    {
                        valorParcela: valorParcela,
                        dataVencimento: dataDeVencimento
                    }
                );
            }

            const sobra = (infoFormaPagamentoFidelidade.valor - arrayTemp.reduce((total, item) => total + (item.valorParcela || 0), 0));
            console.log("SOBRA FIDELIDADE", sobra);
            if(sobra != 0){
                arrayTemp[0].valorParcela += sobra;
            }

            setFormasPagSelecFidelidade(arrayTemp);

        }

    }, [infoFormaPagamentoFidelidade, qtdParcelasFidelidade]);

    useEffect(() => {

        buscaProdutosPorDescricao();
        
    }, [pagina]);

    useEffect(() => {

        
        let UF_selecionado = "";

        estadosClientesFidelidade.forEach((uf) => {
           if(uf.IdUf == UfClienteFidelidade){
                UF_selecionado = uf.UF;
           } 
        });

        if(UfClienteFidelidade != null && UfClienteFidelidade != ""){

            //carregar cidades
            try{

                axios.post(global.URL_BASE + global.CAMINHO_CARREGACIDADES, {
                    uf: UF_selecionado
                })
                .then((response) => {

                    let resultData = response.data;

                    if(resultData.code === 0){

                        let cidades = JSON.parse(resultData.content);   
                        setCidadesClientesFidelidade(cidades);

                    }else{

                        setShowModalErro(true);
                        setErrorMsg(resultData.msg);
                    }
                    
                })
                .catch((error) => {
                    Sentry.captureException(error);

                    setShowModalErro(true);
                    setErrorMsg("Ocorreu um erro ao carregar as cidades, por favor, contate o suporte: " +  error.message);
                });

            }catch(ex){

                Sentry.captureException(ex);

                setShowModalErro(true);
                setErrorMsg("Ocorreu um erro ao carregar as cidades, por favor, contate o suporte: " +  ex.message);

            }

        }

    }, [UfClienteFidelidade, estadosClientesFidelidade]);

    useEffect(() => {

        getData();

    }, []);

    // console.log("Produtos:", produtos);

    // console.log("Formas Pagamento:", formasDePagamentosSelecionadas);
    // console.log("Formas Pagamentos Dinheiro: ", formasPagSelecDinheiros);
    // console.log("Formas Pagamentos Fidelidade: ", formasPagSelecFidelidade);

    // console.log("Ip Origem:", ipUser);
    // console.log("Mac Adress Origem:", macAddresses);

    return (

        <Container fluid className='bg-light' style={{maxWidth: "1000px", paddingBottom: "1%"}}>

            {isScanning && (
                <ScannerOverlay setIsScanning={setIsScanning} setBarcode={setBarcode} funcPos={funcPosLeitor} />
            )}

            {/* Elemento para transformar em PDF */}
            <Container 
                className='hidden' 
                style={{
                    display: 'none', 
                    fontFamily: "Courier New" 
                }}
            >
                <div id="containerPdf" ref={(refer) => (componentRef = refer)}>
                    <div>
                        <div style={{textAlign: "center", border: "1px solid black"}}>

                            <h1>{user != null ? user.operador.filialSelecionada.empresaRazaoSocial : ""}</h1>

                        </div>
                    </div>
                    <div>
                        <div style={{textAlign: "center", alignItems: "center"}}>
                        
                            { user != null ? user.operador.filialSelecionada.empresaEndLogradouro == null ? "" : user.operador.filialSelecionada.empresaEndLogradouro : ""} { user != null ? user.operador.filialSelecionada.empresaEndNumero == null ? "" : user.operador.filialSelecionada.empresaEndNumero : ""}
                        
                        </div>
                    </div>
                    <div>
                        <div style={{textAlign: "center", alignItems: "center"}}>
                            { user != null ? user.operador.filialSelecionada.empresaEndBairro == null ? "" : user.operador.filialSelecionada.empresaEndBairro : ""}
                        </div>
                    </div>
                    <div>
                        <div style={{textAlign: "center", alignItems: "center"}}>
                            { user != null ? user.operador.filialSelecionada.empresaEndCidade == null ? "" : user.operador.filialSelecionada.empresaEndCidade : ""} /{ user != null ? user.operador.filialSelecionada.empresaEndUF == null ? "" : user.operador.filialSelecionada.empresaEndUF : ""}
                        </div>
                    </div>
                    <div>
                        <div style={{textAlign: "center", alignItems: "center"}}>

                            TICKET N. {cupom == null ? "" : cupom}
                            
                        </div>
                    </div>
                    <div>
                        <div >
                            {new Date().getDate().toString().padStart(2, '0') + '/' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '/' + new Date().getFullYear() + ' ' + new Date().getHours().toString().padStart(2, '0') + ':' + new Date().getMinutes().toString().padStart(2, '0')}
                        </div>
                    </div>
                    <div>
                        <div>
                            <hr />
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>
                                <div style={{
                                    display: "flex", /* Define um container flexível */
                                    flexDirection: "row", /* Organiza os itens em linhas */
                                    justifyContent: "space-between", /* Distribui os itens uniformemente ao longo do contêiner */
                                    alignItems: "center", /* Alinha os itens verticalmente no centro */
                                    
                                }}>
                                    <div style={{flex: 1, textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                    </div>
                                    <div style={{flex: 1, textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                        ITEM CÓDIGO 
                                    </div>
                                    <div style={{flex: 1, textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                        DESCRIÇÃO
                                    </div>
                                    <div style={{flex: 1, textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                        Qtd
                                    </div>
                                    <div style={{flex: 1, textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                        PREÇO
                                    </div>
                                    
                                    <div style={{flex: 1, textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                        SUBTOTAL
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <hr />
                                    </div>
                                </div>
                                {produtos.map((item, index) => {
                                    
                                    return (
                                        <div key={index} style={{
                                            display: "flex", /* Define um container flexível */
                                            flexDirection: "row", /* Organiza os itens em linhas */
                                            justifyContent: "space-between", /* Distribui os itens uniformemente ao longo do contêiner */
                                            alignItems: "center", /* Alinha os itens verticalmente no centro */
                                            border: "1% solid black"
                                        }}>
                                            <div style={{flex: 1, border: "1% solid black", textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                                {item.sequencia}
                                            </div>
                                            <div style={{flex: 1, border: "1% solid black", textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                                {item.produto.codigoBarras}
                                            </div>
                                            <div style={{flex: 1, border: "1% solid black", textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                                {item.produto.nomeProduto}
                                            </div>
                                            <div style={{flex: 1, border: "1% solid black", textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                                {item.qtd}
                                            </div>
                                            <div style={{flex: 1, border: "1% solid black", textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                                {item.preco}
                                            </div>
                                            <div style={{flex: 1, border: "1% solid black", textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                                {"R$ " + (item.preco * item.qtd).toFixed(2).replaceAll('.', ',')}
                                            </div>
                                        </div>
                                    );
                                    
                                })}
                                <div>
                                    <div>
                                        <hr />
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex", /* Define um container flexível */
                                    flexDirection: "row", /* Organiza os itens em linhas */
                                    justifyContent: "space-between", /* Distribui os itens uniformemente ao longo do contêiner */
                                    alignItems: "center", /* Alinha os itens verticalmente no centro */
                                    
                                }}>
                                    <div>
                                        Soma dos Itens: 
                                    </div>
                                    <div>
                                        {"R$" + parseFloat(total).toFixed(2).replaceAll('.', ',')}
                                    </div>
                                </div>

                                {desconto > 0 && desconto != null && desconto != undefined 
                                ?
                                    <div style={{
                                        display: "flex", /* Define um container flexível */
                                        flexDirection: "row", /* Organiza os itens em linhas */
                                        justifyContent: "space-between", /* Distribui os itens uniformemente ao longo do contêiner */
                                        alignItems: "center", /* Alinha os itens verticalmente no centro */
                                    }}>
                                        <div>
                                            Desconto:  
                                        </div>
                                        <div style={{justifyContent: "rigth", justifyItems: "rigth", justifySelf: "rigth"}}>
                                            {"R$" + (-desconto.toFixed(2).replaceAll('.', ','))}
                                        </div>
                                    </div>
                                :
                                null}

                                                                    
                                <div style={{
                                    display: "flex", /* Define um container flexível */
                                    flexDirection: "row", /* Organiza os itens em linhas */
                                    justifyContent: "space-between", /* Distribui os itens uniformemente ao longo do contêiner */
                                    alignItems: "center", /* Alinha os itens verticalmente no centro */
                                    
                                }}>
                                    <div>
                                        Total: 
                                    </div>
                                    <div>
                                        {"R$" + total.toFixed(2).replaceAll('.', ',')}
                                    </div>
                                </div>
                                

                                <div style={{
                                    display: "flex", /* Define um container flexível */
                                    flexDirection: "row", /* Organiza os itens em linhas */
                                    justifyContent: "space-between", /* Distribui os itens uniformemente ao longo do contêiner */
                                    alignItems: "center", /* Alinha os itens verticalmente no centro */
                                }}>
                                    <div>
                                        Troco:  
                                    </div>
                                    <div style={{justifyContent: "rigth", justifyItems: "rigth", justifySelf: "rigth"}}>
                                        {"R$" + troco.toFixed(2).replaceAll('.', ',')}
                                    </div>
                                </div>
                                <div>
                                    <hr />
                                </div>
                                <div>
                                    <div>
                                        Formas de Pagamento Utilizadas:
                                    </div>
                                </div>

                                <div >

                                    {formasDePagamentosSelecionadas.map((item, index) => {
                                        
                                        if(item[0].formaPagamento != 'DINHEIRO'){

                                            return <div key={index}>

                                                <div style={{
                                                    display: "flex", /* Define um container flexível */
                                                    flexDirection: "row", /* Organiza os itens em linhas */
                                                    justifyContent: "space-between", /* Distribui os itens uniformemente ao longo do contêiner */
                                                    alignItems: "center", /* Alinha os itens verticalmente no centro */
                                                }}>
                                                    <div style={{flex: 1, border: "1% solid black"}}>
                                                    
                                                        {item[0].formaPagamento}

                                                    </div>
                                                    <div style={{flex: 1, border: "1% solid black"}}>
                                                        {"R$" + parseFloat(item[1]).toFixed(2).replaceAll('.', ',') }
                                                    </div>
                                                </div>

                                            </div>

                                        } 

                                    })}

                                    {formasPagSelecDinheiros.length > 0 
                                    ? <div >
                                            <div style={{
                                                display: "flex", /* Define um container flexível */
                                                flexDirection: "row", /* Organiza os itens em linhas */
                                                justifyContent: "space-between", /* Distribui os itens uniformemente ao longo do contêiner */
                                                alignItems: "center", /* Alinha os itens verticalmente no centro */
                                            }}>
                                                <div style={{flex: 1, border: "1% solid black"}}>
                                                
                                                    DINHEIRO

                                                </div>
                                                <div style={{flex: 1, border: "1% solid black"}}>
                                                    {"R$" + valorPagoTotalDinheiro.toFixed(2).replaceAll('.', ',')}
                                                </div>
                                            </div>
                                        </div>
                                    : null }

                                </div>

                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <hr/>
                        </div>
                    </div>
                    <div style={{
                        display: "flex", /* Define um container flexível */
                        flexDirection: "row", /* Organiza os itens em linhas */
                        justifyContent: "space-between", /* Distribui os itens uniformemente ao longo do contêiner */
                        alignItems: "center", /* Alinha os itens verticalmente no centro */
                    }}>
                        <div>
                            CPF: 
                        </div>
                        <div>
                            {cpfcnpjCliente}
                        </div>
                    </div>
                    <div>
                        <div>
                            <hr/>
                        </div>
                    </div>
                    <div style={{
                        display: "flex", /* Define um container flexível */
                        flexDirection: "row", /* Organiza os itens em linhas */
                        justifyContent: "space-between", /* Distribui os itens uniformemente ao longo do contêiner */
                        alignItems: "center", /* Alinha os itens verticalmente no centro */
                    }}>
                        <div>
                            Operador:
                        </div>
                        <div>
                            {(user != null && user.operador.codOperador) + " " + (user != null && user.operador.nomeOperador) + " CX " + (user != null && user.operador.numCaixa)}
                        </div>
                    </div>
                    <div>
                        <div>
                            <hr/>
                        </div>
                    </div>
                    <div style={{textAlign: "center", alignItems: "center"}}>
                        <div>
                            Obrigado e Volte Sempre!
                        </div>
                    </div>
                    <div>
                        <div>
                            <hr/>
                        </div>
                    </div>
                    <div style={{textAlign: "center", alignItems: "center"}}>
                        <h2>Obrigado pela Preferência</h2>
                        {/* <h2>Volte Sempre!</h2> */}
                    </div>
                </div>
            </Container> 

            {/* Modal de selecionar Produtos por nomes */}
            <Modal
            show={showModalAdicionarProdutos}
            onHide={() => {
                setShowModalAdicionarProdutos(false);
                setDescricaoPesquisa("");
            }}
            keyboard={false}
            id="modalAdicionarProdutos"
            centered
            size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title><h1>Selecionar produto</h1></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col style={{textAlign: "center", alignItems: "center"}}>
                                <FloatingLabel
                                label="Nome ou EAN: "
                                >
                                    <Form.Control 
                                        type="text" 
                                        placeholder="" 
                                        value={descricaoPesquisa} 
                                        onChange={(e) => {
                                            setDescricaoPesquisa(e.target.value);
                                        }}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col
                            
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                xl={3}
                                style={window.innerWidth < 768 ? {verticalAlign: 'middle', marginTop: 10, marginBottom: 10} : {marginBottom: 10}}
                            >
                                <div className="d-grid gap-2" >
                                    <Button
                                    variant="primary"
                                    onClick={handleBuscarProdutosPorNome}
                                    ><CiSearch size={window.innerWidth < 768 ? 30 : 45 }/></Button>
                                </div>
                            </Col>
                        </Row>
                        <MobileView>
                            <Row style={{marginBottom: 5, marginTop: 5}}>
                                <div className="d-grid gap-2">
                                    <Button onClick={() => {
                                        setDescricaoPesquisa("");
                                        setShowModalAdicionarProdutos(false);
                                        setFuncPosLeitor((barcode) => (barcode) => {
                                            setShowModalAdicionarProdutos(true);
                                            setDescricaoPesquisa(barcode);
                                        });
                                        setIsScanning(true);
                                    }}>
                                        <FaCamera  size={25} />
                                    </Button>
                                </div>
                            </Row>
                        </MobileView>

                        <Row >
                            <Col>
                                <div className="d-grid gap-2">
                                    <Button
                                    variant="danger"
                                    onClick={() => {

                                        setShowModalAdicionarProdutos(false);
                                    }}
                                    >Fechar</Button>
                                </div>
                            </Col>

                        </Row>

                        <Container>

                            {produtosTodosPesquisa.map((item, index) => {

                                return(
                                    <Row key={index} style={{marginBottom: "1%"}}>

                                        <Col 
                                        xs={6}
                                        sm={6}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                        style={{textAlign: "center", alignItems: "center"}} className='border border-dark'>
                                            <FloatingLabel
                                            label="EAN: "
                                            className="mb-3"
                                            >
                                                <Form.Control 
                                                    type="text" 
                                                    value={item.codigoBarras}
                                                    onChange={(e) => {
                                                        e.target.value = item.codigoBarras 
                                                    }}
                                                    style={{fontSize: "100%", marginTop: "5%", marginBottom: "5%"}}
                                                />
                                            </FloatingLabel>
                                        </Col>
                                        
                                        <Col 
                                        xs={6}
                                        sm={6}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                        style={{textAlign: "center", alignItems: "center"}} className='border border-dark'>
                                            <FloatingLabel
                                            label="PREÇO: "
                                            className="mb-3"
                                            >
                                                <Form.Control 
                                                    type="text" 
                                                    value={"R$" + item.precoProduto.replaceAll(".", ",")}
                                                    onChange={(e) => {
                                                        e.target.value = "R$" + item.precoProduto.replaceAll(".", ",")
                                                    }}
                                                    style={{ fontSize: "100%", marginTop: "5%", marginBottom: "5%"}}
                                                />
                                            </FloatingLabel>
                                            
                                        </Col>

                                        <Col
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                        style={{textAlign: "center", alignItems: "center"}} className='border border-dark'>
                                            <FloatingLabel
                                            label="NOME: "
                                            className="mb-3"
                                            >
                                                <Form.Control 
                                                    type="text" 
                                                    value={item.nomeProduto}
                                                    onChange={(e) => {
                                                        e.target.value = item.nomeProduto
                                                    }}
                                                    style={{fontSize: "100%", marginTop: "5%", marginBottom: "5%"}}
                                                />
                                            </FloatingLabel>
                                            
                                        </Col>

                                        <Col 
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                        style={{textAlign: "center", alignItems: "center"}} 
                                        className='border border-dark'
                                        >

                                            <div className="d-grid gap-2" style={{alignSelf: "center"}}>
                                                <Button
                                                variant="success"
                                                onClick={() => {
                                                    
                                                    //selecionar produtos

                                                    setProdutoSelecionado(item);
                                                    setNomeProdutoSelecionado(item.nomeProduto);
                                                    setPreco(item.precoProduto);
                                                    setCodProd(item.codigoBarras);
                                                    setShowModalAdicionarProdutos(false);

                                                }}
                                                style={{ fontSize: "100%", marginTop: "5%", marginBottom: "5%"}}
                                                ><LuPlusCircle size={27} /></Button>
                                            </div>

                                        </Col>

                                    </Row>
                                );
                                
                            })}

                            {produtosTodosPesquisa != null && produtosTodosPesquisa != undefined && produtosTodosPesquisa.length > 0
                                
                                ?<Container 
                                    fluid 
                                    style={{alignContent: "center", alignItems: "center", alignSelf: "center", marginTop: "5%"}} 
                                >
                                    
                                    <Pagination style={{alignContent: "center", alignItems: "center", alignSelf: "center", justifyContent: "center", justifyItems: "center"}} size='lg' >
                                        <Pagination.First 
                                            onClick={() => {

                                                if(pagina > 1){

                                                    setPagina(1);

                                                }
                                                
                                            }} 
                                        />
                                        <Pagination.Prev 
                                        
                                            onClick={() => {

                                                if(pagina > 1){
                                                    if(pagina > 1){

                                                        setPagina(pagina - 1);
                                                        
                                                    }else{

                                                        setPagina(1);

                                                    }
                                                }

                                            }}
                                        
                                        />
                                        
                                        <Pagination.Item active>{pagina}</Pagination.Item>

                                        <Pagination.Next 
                                        
                                            onClick={() => {

                                                let totalPaginas = Math.ceil(numTotalRegistro / global.VALOR_POR_PAGINA);

                                                if(pagina + 1 <= totalPaginas){

                                                    setPagina(pagina + 1);

                                                }
                                                
                                            }}
                                        
                                        />
                                        <Pagination.Last 
                                        
                                            onClick={() => {

                                                let totalPaginas = Math.ceil(numTotalRegistro / global.VALOR_POR_PAGINA);
                                                setPagina(totalPaginas);

                                            }}
                                        
                                        />
                                    </Pagination>
                                    

                                </Container>

                                :null
                            
                            }

                        </Container>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Deslogar */}
            <ModalDeslogar showModal={showModalDeslogar} setShowModal={ (e) => setShowModalDeslogar(e) }/>

            {/* Modal Admin Senha */}
            <ModalAdminSenha showModal={showModalAdminSenha} setShowModal={ (e) => setShowModalAdminSenha(e)  } user={user} funcPos={ funcModalAdminSenha } />

            {/* Modal Cliente */}
            <Modal
            show={showModalCliente}
            backdrop="static"
            keyboard={false}
            id="modalCliente"
            size="lg"
            onHide={() => setShowModalCliente(false)}
            centered
            >
                <Modal.Header>
                    <Modal.Title><h1><FaUser size={40}/>Cliente</h1> </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-light'>
                    <Container>
                        <Row style={{marginBottom: "1%"}}> 
                            <Col
                            
                            xs={12}
                            sm={12}
                            md={12}
                            lg={8}
                            xl={8}
                            
                            >
                                <FloatingLabel
                                controlId="floatingInput"
                                label="CPF/CNPJ"
                                className="mb-3"
                                >
                                    <Form.Control
                                    type="text"
                                    placeholder="CPF/CNPJ"
                                    value={cpfcnpjClientePesquisaModal}
                                    disabled={(idClienteFidelidade != null && idClienteFidelidade != undefined && idClienteFidelidade != "" && idClienteFidelidade != 0)}
                                    onChange={(e) => {

                                        let v = e.target.value;

                                        v = v.replace(/\D/g, "")

                                        if (v.length <= 11) {
                                            v = v.replace(/(\d{3})(\d)/, "$1.$2")
                                            v = v.replace(/(\d{3})(\d)/, "$1.$2")
                                            v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
                                        } else if(v.length <= 14){ 
                                            v = v.replace(/^(\d{2})(\d)/, "$1.$2")
                                            v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
                                            v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
                                            v = v.replace(/(\d{4})(\d)/, "$1-$2")
                                        }else{
                                            v = cpfcnpjClientePesquisaModal;
                                        }

                                        setCpfcnpjClientePesquisaModal(v);
                                    
                                    }}

                                    />
                                </FloatingLabel>
                            </Col>
                            <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={2}
                            xl={2}
                            >
                                <div className="d-grid gap-2">
                                    <Button
                                    id='btnPesquisaCpfCnpjClienteFidelidadeModal'
                                    variant="primary"
                                    disabled={(idClienteFidelidade != null && idClienteFidelidade != undefined && idClienteFidelidade != "" && idClienteFidelidade != 0)}
                                    onClick={() => {

                                        if(cpfcnpjClientePesquisaModal != ""){
                                            if(cpfcnpjClientePesquisaModal.length == 14 || cpfcnpjClientePesquisaModal.length == 18){
                                
                                                if(!validaCpfCnpj(cpfcnpjClientePesquisaModal)){
                                
                                                    setShowModalLoad(false);
                                                    setShowModalErro(true);
                                                    setErrorMsg("CPF/CNPJ invalido!");
                                
                                                    return;
                                                }

                                                buscaClientes(cpfcnpjClientePesquisaModal);

                                            }
                                        }

                                    }}
                                    ><CiSearch size={45}/></Button>
                                </div>
                            </Col>
                            <Col
                            
                            xs={12}
                            sm={12}
                            md={12}
                            lg={2}
                            xl={2}
                            
                            >
                                <div className="d-grid gap-2">
                                    <Button
                                    id='btnAbrirModalPesquisaNomeClienteFidelidadeModal'
                                    variant="primary"
                                    disabled={(idClienteFidelidade != null && idClienteFidelidade != undefined && idClienteFidelidade != "" && idClienteFidelidade != 0)}
                                    onClick={async () => {

                                        setShowModalNomeCliente(true);
                                        setShowModalCliente(false);
                                        
                                    }}
                                    ><FaClipboardUser 
                                    size={45}
                                    /></Button>
                                </div>
                            </Col>
                            
                        </Row>
                        <Row>
                            <hr></hr>
                        </Row>
                            <Row style={{marginBottom: "1%"}}>
                                <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                >
                                    
                                    <FloatingLabel
                                        controlId="floatingInputNomeModalCliente"
                                        label="Nome"
                                        className="mb-3"
                                    >
                                        <Form.Control 
                                            type="text"
                                            placeholder=""
                                            disabled={(idClienteFidelidade != null && idClienteFidelidade != undefined && idClienteFidelidade != "" && idClienteFidelidade != 0)}
                                            value={nomeClienteFidelidade}
                                            onChange={(e) => {
                                                setNomeClienteFidelidade(e.target.value);
                                            }}
                                            //onBlur={handleBlur}
                                        />
                                    </FloatingLabel>

                                </Col>
                            </Row>

                            <Row style={{marginBottom: "1%"}}>
                                <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                >
                                    
                                    <FloatingLabel
                                        controlId="floatingInputEmailModalCliente"
                                        label="Email"
                                        className="mb-3"
                                    >
                                        <Form.Control 
                                            type="text"
                                            placeholder=""
                                            disabled={(idClienteFidelidade != null && idClienteFidelidade != undefined && idClienteFidelidade != "" && idClienteFidelidade != 0)}
                                            value={emailClienteFidelidade}
                                            onChange={(e) => {
                                                setEmailClienteFidelidade(e.target.value);
                                            }}
                                            //onBlur={handleBlur}
                                        />
                                    </FloatingLabel>

                                </Col>
                            </Row>

                            <Row style={{marginBottom: "1%"}}>
                                <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={7}
                                xl={7}
                                >
                                    
                                    <FloatingLabel
                                        controlId="floatingInputCelularModalCliente"
                                        label="Celular"
                                        className="mb-3"
                                    >
                                        <Form.Control 
                                            type="text"
                                            placeholder=""
                                            disabled={(idClienteFidelidade != null && idClienteFidelidade != undefined && idClienteFidelidade != "" && idClienteFidelidade != 0)}
                                            value={celularClienteFidelidade}
                                            onChange={(e) => {
                                                setcelularClienteFidelidade(e.target.value);
                                            }}
                                            //onBlur={handleBlur}
                                        />
                                    </FloatingLabel>

                                </Col>
                                <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={5}
                                xl={5}
                                >
                                    
                                    <FloatingLabel
                                        controlId="floatingInputNascimentoModalCliente"
                                        label="Nasc"
                                        className="mb-3"
                                    >
                                        <Form.Control 
                                            type="text"
                                            placeholder=""
                                            as={InputMask}
                                            mask={"99/99/9999"}
                                            disabled={(idClienteFidelidade != null && idClienteFidelidade != undefined && idClienteFidelidade != "" && idClienteFidelidade != 0)}
                                            value={nascimentoClienteFidelidade}
                                            onChange={(e) => {
                                                setNascimentoClienteFidelidade(e.target.value);
                                            }}
                                            //onBlur={handleBlur}
                                        />
                                    </FloatingLabel>

                                </Col>
                            </Row>

                            <Row style={{marginBottom: "1%"}}>
                                <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={5}
                                xl={5}
                                >
                                    
                                    <FloatingLabel
                                        controlId="floatingInputCepModalCliente"
                                        label="CEP"
                                        className="mb-3"
                                    >
                                        <Form.Control 
                                            type="text"
                                            placeholder=""
                                            as={InputMask}
                                            mask="99999-999"
                                            disabled={(idClienteFidelidade != null && idClienteFidelidade != undefined && idClienteFidelidade != "" && idClienteFidelidade != 0)}
                                            value={cepClienteFidelidade}
                                            onChange={(e) => {
                                                setCepClienteFidelidade(e.target.value);
                                            }}
                                            //onBlur={handleBlur}
                                        />
                                    </FloatingLabel>

                                </Col>
                                <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={4}
                                xl={4}
                                style={{marginBottom: "3%"}}
                                >

                                    <div className="d-grid gap-2">
                                        <Button
                                        variant="primary"
                                        size='lg'
                                        disabled={(idClienteFidelidade != null && idClienteFidelidade != undefined && idClienteFidelidade != "" && idClienteFidelidade != 0)}
                                        onClick={() => {

                                            try{

                                                let data = {
                                        
                                                    idOrg: user.operador.idOrganizacao,
                                                    cep: cepClienteFidelidade,
                                    
                                                };

                                                setShowModalLoad(true);
                                                
                                                axios.post(global.URL_BASE + global.CAMINHO_CONSULTACEP, data)
                                                .then((response) => {
                                    
                                                    let resultData = response.data;
                                    
                                                    if(resultData.code == 0){
                                    
                                                        let data = JSON.parse(resultData.content);

                                                        setEnderecoClienteFidelidade(data.logradouro);
                                                        setBairroClienteFidelidade(data.bairro);
                                    
                                                    }else{

                                                        setErrorMsg(resultData.msg);
                                                        setShowModalErro(true);
                                    
                                                    }
                                                    
                                                })
                                                .catch((error) => {
                                                    Sentry.captureException(error);
                                                    setErrorMsg("Ocorreu um erro ao tentar consultar o CEP: " + error.message);
                                                    setShowModalErro(true);
                                                })
                                                .finally(() => {
                                                    setShowModalLoad(false);
                                                });
                                    
                                            }catch(ex){

                                                Sentry.captureException(ex);

                                                setShowModalLoad(false);
                                                setErrorMsg("Ocorreu um erro ao tentar consultar o CEP: " + ex.message);
                                                setShowModalErro(true);
                                    
                                            }



                                        }}
                                        ><CiSearch size={42}/>Buscar CEP</Button>
                                    </div>

                                </Col>
                                <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={3}
                                xl={3}
                                >

                                    <FloatingLabel controlId="floatingInputUFModalCliente" label="UF" className="mb-3">
                                        <Form.Control 
                                            as="select"
                                            disabled={(idClienteFidelidade != null && idClienteFidelidade != undefined && idClienteFidelidade != "" && idClienteFidelidade != 0)}
                                            value={UfClienteFidelidade}
                                            onChange={(e) => {
                                                setUfClienteFidelidade(e.target.value);
                                            }}
                                        >
                                            <option value="">Selecione...</option>
                                            {estadosClientesFidelidade.map((estado, index) => (
                                                <option key={index} value={estado.IdUf}>{estado.UF}</option>
                                            ))}
                                        </Form.Control>
                                    </FloatingLabel>

                                </Col>
                            </Row>

                            <Row style={{marginBottom: "1%"}}>
                                <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={7}
                                xl={7}
                                >
                                    
                                    <FloatingLabel
                                        controlId="floatingInputEnderecoModalCliente"
                                        label="Endereco"
                                        className="mb-3"
                                    >
                                        <Form.Control 
                                            type="text"
                                            placeholder=""
                                            disabled={(idClienteFidelidade != null && idClienteFidelidade != undefined && idClienteFidelidade != "" && idClienteFidelidade != 0)}
                                            value={enderecoClienteFidelidade}
                                            onChange={(e) => {
                                                setEnderecoClienteFidelidade(e.target.value);
                                            }}
                                            //onBlur={handleBlur}
                                        />
                                    </FloatingLabel>

                                </Col>
                                <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={5}
                                xl={5}
                                >
                                    
                                    <FloatingLabel
                                        controlId="floatingInputNumeroModalCliente"
                                        label="Numero"
                                        className="mb-3"
                                    >
                                        <Form.Control 
                                            type="text"
                                            placeholder=""
                                            disabled={(idClienteFidelidade != null && idClienteFidelidade != undefined && idClienteFidelidade != "" && idClienteFidelidade != 0)}
                                            value={numeroClienteFidelidade}
                                            onChange={(e) => {
                                                setNumeroClienteFidelidade(e.target.value);
                                            }}
                                        />
                                    </FloatingLabel>

                                </Col>
                            </Row>

                            <Row style={{marginBottom: "1%"}}>
                                <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                >
                                    
                                    <FloatingLabel
                                        controlId="floatingInputBairroModalCliente"
                                        label="Bairro"
                                        className="mb-3"
                                    >
                                        <Form.Control 
                                            type="text"
                                            placeholder=""
                                            disabled={(idClienteFidelidade != null && idClienteFidelidade != undefined && idClienteFidelidade != "" && idClienteFidelidade != 0)}
                                            value={bairroClienteFidelidade}
                                            onChange={(e) => {
                                                setBairroClienteFidelidade(e.target.value);
                                            }}
                                        />
                                    </FloatingLabel>

                                </Col>
                            </Row>

                            <Row style={{marginBottom: "1%"}}>
                                <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                >

                                    <FloatingLabel controlId="floatingInputCidadeModalCliente" label="Cidade" className="mb-3">
                                        <Form.Control 
                                            as="select"
                                            disabled={(idClienteFidelidade != null && idClienteFidelidade != undefined && idClienteFidelidade != "" && idClienteFidelidade != 0)}
                                            value={cidadeClienteFidelidade}
                                            onChange={(e) => {
                                                setCidadeClienteFidelidade(e.target.value);
                                            }}
                                        >
                                            <option value="">Selecione...</option>
                                            {cidadesClientesFidelidade.map((cidade, index) => (
                                                <option key={index} value={cidade.IdCidade}>{cidade.Cidade}</option>
                                            ))} 
                                        </Form.Control>
                                    </FloatingLabel>

                                </Col>
                            </Row>

                            {/* //botões de ação */}
                            <Row
                            style={{marginBottom: "1%"}}
                            >
                                <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                >
                                    <div className="d-grid gap-2">
                                        <Button
                                        variant="primary"
                                        id='btnConfirmarClienteFidelidadeModal'
                                        onClick={() => {

                                            if(idClienteFidelidade != null && idClienteFidelidade != undefined && idClienteFidelidade != "" && idClienteFidelidade != 0){
                                                setShowModalCliente(false);
                                            }else{

                                                if(validaCpfCnpj(cpfcnpjClientePesquisaModal)){
                                                    let data = {

                                                        idOrganizacao: user.operador.idOrganizacao,
                                                        idFilial: user.operador.idFilial,
                                                        dadosJson:
                                                            {
                                                                idCliente: idClienteFidelidade == null || idClienteFidelidade == undefined || idClienteFidelidade == "" ? "0" : idClienteFidelidade.toString(),
                                                                documento: cpfcnpjClientePesquisaModal,
                                                                nomeCliente: nomeClienteFidelidade,
                                                                celular: celularClienteFidelidade,
                                                                email: emailClienteFidelidade,
                                                                endereco: enderecoClienteFidelidade,
                                                                enderecoNumero: numeroClienteFidelidade,
                                                                enderecoBairro: bairroClienteFidelidade,
                                                                dataNascimento: nascimentoClienteFidelidade,
                                                                enderecoCEP: cepClienteFidelidade,
                                                                idUf: UfClienteFidelidade,
                                                                idCidadeIBGE: cidadeClienteFidelidade
                                                            },
                                                        userWS: global.USER_WS,
                                                        passWS: global.PASS_WS
                                                        
                                                    };

                                                    setShowModalLoad(true);
                                            
                                                    axios.post(global.URL_BASE + global.CAMINHO_CADASTRAEDITACLIENTE, data)
                                                    .then((response) => {
                                            
                                                        let resultData = response.data;
                                            
                                                        if(resultData.code != 0){

                                                            setErrorMsg(resultData.msg);
                                                            setShowModalErro(true);
                                            
                                                        }else{

                                                            setIdClienteFidelidade(resultData.content);
                                                            setShowModalCliente(false);
                                        
                                                        }
                                                        
                                                    })
                                                    .catch((error) => {

                                                        Sentry.captureException(error);

                                                        setErrorMsg("Ocorreu algum erro ao confirmar o cliente. Tente novamente!");
                                                        setShowModalErro(true);

                                                    })
                                                    .finally(() => {
                                                        setShowModalLoad(false);
                                                    });

                                                }else{
                                                    setShowModalErro(true);
                                                    setErrorMsg("CPF/CNPJ inválido!");
                                                    setShowModalLoad(false);
                                                }

                                            }
                                
                                        }}
                                        >Confimar Cliente</Button>
                                    </div>
                                </Col>
                            </Row>

                            {
                                controleCupomJaIniciado == false &&  (produtos != null ? produtos.length <= 0 : true )
                                ?<Row
                                style={{marginBottom: "1%"}}
                                >
                                    <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    >
                                        <div className="d-grid gap-2">
                                            <Button
                                            variant="danger"
                                            onClick={() => {

                                                setShowModalCliente(false);

                                                setCpfcnpjClientePesquisaModal("");
                                                setIdClienteFidelidade("");
                                                setNomeClienteFidelidade("");
                                                setEmailClienteFidelidade("");
                                                setcelularClienteFidelidade("");
                                                setNascimentoClienteFidelidade("");
                                                setCepClienteFidelidade("");
                                                setUfClienteFidelidade("");
                                                setEnderecoClienteFidelidade("");
                                                setNumeroClienteFidelidade("");
                                                setBairroClienteFidelidade("");
                                                setCidadeClienteFidelidade("");

                                            }}
                                            >
                                                {
                                                    idClienteFidelidade != null && idClienteFidelidade != undefined && idClienteFidelidade != "" && idClienteFidelidade != 0
                                                    ?
                                                        "Remover cliente"
                                                    :
                                                        "Não informar cliente"
                                                }
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                :
                                null
                            }
                            
                    </Container>
                </Modal.Body>

            </Modal>

            {/* Modal Busca Nome Cliente */}
            <Modal
            show={showModalNomeCliente}
            keyboard={false}
            onHide={() => {
                setShowModalNomeCliente(false);
                setShowModalCliente(true);
            }}
            id="modalBuscaNomeCliente"
            size="lg"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title ><h1>Buscar nome cliente</h1> </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-light'>
                    <Container style={{justifyContent: "center", justifyItems: "center", marginBottom: "1%"}}>
                        <Row>
                            <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={10}
                            xl={10}
                            style={{marginBottom: "1%"}}
                            >
                                <Form.Control 
                                    type="text"
                                    placeholder="Nome do cliente"
                                    value={nomeClienteBusca}
                                    onChange={(e) => {
                                        setNomeClienteBusca(e.target.value);
                                    }}
                                />
                            </Col>
                            <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={2}
                            xl={2}
                            >
                                <div className="d-grid gap-2">
                                    <Button
                                    variant="primary"
                                    onClick={async () => {
                                        
                                        if(nomeClienteBusca != ""){

                                            let data = {
                                                
                                                idOrganizacao: user.operador.idOrganizacao,
                                                idFilial: user.operador.idFilial,
                                                numeroCaixa: user.operador.numCaixa,
                                                userWS: global.USER_WS,
                                                passWS: global.PASS_WS,
                                                cpf_cnpj: "",
                                                nome: nomeClienteBusca
                                                  
                                            };

                                            setNomeClienteBusca("");
                                            setShowModalLoad(true);
                                    
                                            axios.post(global.URL_BASE + global.CAMINHO_CONSULTACLIENTE, data)
                                            .then((response) => {
                                    
                                                let resultData = response.data;

                                                if(resultData.code == 0){

                                                    setClientesBuscaClientesNome(resultData.content.clientePDV);

                                                }else{

                                                    setErrorMsg("Cliente não pode ser encontrado! Tente novamente!");
                                                    setShowModalErro(true);

                                                }
                                                
                                            })
                                            .catch((error) => {
                                                Sentry.captureException(error);

                                                setErrorMsg("Ocorreu algum erro ao consultar o cliente. Tente novamente!");
                                                setShowModalErro(true);

                                            }).finally(() => {
                                                setShowModalLoad(false);
                                            });

                                        }

                                    }}
                                    >Buscar</Button>
                                </div>
                            </Col>
                        </Row>
                        <Container style={{marginTop: "1%"}}>
                            {clientesBuscaClientesNome != null && clientesBuscaClientesNome != undefined && clientesBuscaClientesNome.length > 0
                            ?clientesBuscaClientesNome.map((item, index) => (
                              
                                <Row key={item.documento}>

                                    <Col
                                    xs={6}
                                    sm={6}
                                    md={6}
                                    lg={5}
                                    xl={5}
                                    >
                                    
                                        <FloatingLabel
                                        controlId="floatingInput"
                                        label="Nome"
                                        className="mb-3"
                                        >
                                            <Form.Control
                                            type="text"
                                            defaultValue={item.nomeCliente}
                                            disabled

                                            />
                                        </FloatingLabel>
                                        
                                    </Col>

                                    <Col
                                    xs={6}
                                    sm={6}
                                    md={6}
                                    lg={4}
                                    xl={4}
                                    >
                                    
                                        <FloatingLabel
                                        controlId="floatingInput"
                                        label="CPF/CNPJ"
                                        className="mb-3"
                                        >
                                            <Form.Control
                                            type="text"
                                            defaultValue={item.documento}
                                            disabled

                                            />
                                        </FloatingLabel>
                                        
                                    </Col>

                                    <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={3}
                                    xl={3}
                                    >

                                        <div className="d-grid gap-2">
                                            <Button
                                            onClick={() => {

                                                let cliente = item;
                                                setUfClienteFidelidade(cliente.idUf);
                                                setIdClienteFidelidade(cliente.idCliente);
                                                setNomeClienteFidelidade(cliente.nomeCliente);
                                                setEmailClienteFidelidade(cliente.email);
                                                setcelularClienteFidelidade(cliente.celular);
                                                setNascimentoClienteFidelidade(cliente.dataNascimento);
                                                setCepClienteFidelidade(cliente.enderecoCEP);
                                                setEnderecoClienteFidelidade(cliente.endereco);
                                                setNumeroClienteFidelidade(cliente.enderecoNumero);
                                                setBairroClienteFidelidade(cliente.enderecoBairro);
                                                setCidadeClienteFidelidade(cliente.idCidadeIBGE);
                                                setCpfcnpjClientePesquisaModal(cliente.documento);

                                                setShowModalNomeCliente(false);

                                            }}><AiOutlineCheck size={43}/></Button>
                                        </div>
                                    
                                        
                                        
                                    </Col>

                                    <hr></hr>
                                
                                </Row>
                                
                            ))
                            :null} 
                        </Container>
                    </Container>
                </Modal.Body>

            </Modal>

            {/* Modal add forma de pagamento */}
            <Modal
            show={showModalFormaDePagamento}
            onHide={() => {
                setShowModalFormaDePagamento(false);
            }}
            keyboard={false}
            id="modalSucess"
            centered
            size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title><h1><LuPlusCircle /> Adicionar forma de pagamento</h1> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    {

                        user != null && user != undefined &&
                        user.formasDePagamentos != null && user.formasDePagamentos != undefined 
                        ?
                            user.formasDePagamentos.map((item, key) => {

                                let FidelidadeRegistrado = false;
        
                                formasDePagamentosSelecionadas.map(() => {
        
                                    if(item.formaPagamento == "FIDELIDADE"){
                                        FidelidadeRegistrado = true;
                                    }
        
                                });
        
                                if( (item.formaPagamento == "FIDELIDADE" && !FidelidadeRegistrado) || (item.formaPagamento != "FIDELIDADE") ){
        
                                    return <Container key={key} style={{marginBottom: "1%"}}>
        
                                        <Row style={{justifyContent: "left", justifyItems: "left"}}>
        
                                            <Col
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            >
                                            
                                                <h3>{item.formaPagamento}</h3>
        
                                            </Col>
        
                                            <Col
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            >
        
                                            {
        
                                                item.formaPagamento != "DINHEIRO" && item.formaPagamento != "FIDELIDADE"
                                                ?<FloatingLabel
                                                controlId={"valorParcelamentoModal" + item.formaPagamento}
                                                label="Parcelar:"
                                                >
                                                    <Form.Select aria-label="Parcelamento">
                                                    {
                                                        (
                                                            () => {
                                                                let li = [];
                                                                if(item.qtdParcelamentoMaximo === 0){
                                                                    li.push(<option key={1} value={(1)}>{(1)+"x"}</option>);
                                                                }else{
                                                                    for (let i = 1; i <= item.qtdParcelamentoMaximo; i++) {
                                                                        li.push(<option key={item.formaPagamento + i} value={(i)}>{(i)+"x"}</option>);
                                                                    }
                                                                }
                                                                
                                                                return li;
                                                            }
                                                        )()
                                                    }
                                                    </Form.Select>
                                                </FloatingLabel>
                                                :null
        
                                            }
        
                                            </Col>
        
                                            <Col
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            >
                                                {item.formaPagamento == "DINHEIRO"
                                                ?
                                                <FloatingLabel
                                                controlId={"valorPagoModal" + item.formaPagamento}
                                                label="Valor: "
                                                className="mb-3"
                                                >
                                                    <Form.Control 
                                                        className="mb-3"
                                                        type="number" step="0.01"
                                                        min={0}
                                                        defaultValue={0}
                                                    />
                                                </FloatingLabel>
                                                :
                                                <FloatingLabel
                                                controlId={"valorPagoModal" + item.formaPagamento}
                                                label="Valor: "
                                                className="mb-3"
                                                
                                                >
                                                    <Form.Control 
                                                        className="mb-3"
                                                        type="number" step="0.01"
                                                        min={0}
                                                        max={ -1 * troco >= 0 ? -1 * troco : 0}
                                                        defaultValue={0}
                                                        onChange={(e) => {
        
                                                            if(e.target.value > -1 * troco){
                                                            
                                                                if(-1 * troco >= 0){
        
                                                                    e.target.value = -1 * troco;
        
                                                                }else{
        
                                                                    e.target.value = 0
        
                                                                }
                                                            
                                                            }
        
                                                        }}
                                                    />
                                                </FloatingLabel>
                                                
                                                }
        
                                            </Col>
        
                                        </Row>
                                        <Row style={{justifyContent: "left", justifyItems: "left"}}>
        
                                            <Col
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            >
                                            
                                                <div className="d-grid gap-2">
                                                    <Button
                                                    variant="primary"
                                                    onClick={() => {
        
                                                        if(total === 0){
        
                                                            setErrorMsg('Nenhum valor foi informado!');
                                                            setShowModalErro(true);
        
                                                        }else{
        
                                                            user.formasDePagamentos.map((item) => {
        
                                                                if(document.getElementById("valorPagoModal" + item.formaPagamento) != null){
                                                                    document.getElementById("valorPagoModal" + item.formaPagamento).value = 0
                                                                }
                                                                
                                                            });
            
                                                            document.getElementById("valorPagoModal" + item.formaPagamento).value = ( (troco * -1) >= 0 ? (troco * -1).toFixed(2) : parseFloat("0,00") );
        
                                                        }
        
                                                    }}
                                                    >Valor Total</Button>
                                                </div>
                                            
                                            </Col>
        
                                            <Col
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            style={{textAlign: "left", justifySelf: "left"}}
                                            >
                                            
                                                <div className="d-grid gap-2">
                                                    <Button
                                                    variant="primary"
                                                    onClick={() => {
        
                                                        if(item.formaPagamento != "DINHEIRO" && item.formaPagamento != "FIDELIDADE"){
        
                                                            if(document.getElementById("valorPagoModal" + item.formaPagamento).value == 0){
        
                                                                setErrorMsg('Nenhum valor foi informado!');
                                                                setShowModalErro(true);
            
                                                            }else{
        
                                                                // let arrayTemp = formasDePagamentosSelecionadas;
                                                                // arrayTemp.push( [item, document.getElementById("valorPagoModal" + item.formaPagamento).value, document.getElementById("valorParcelamentoModal" + item.formaPagamento).value ] );
        
                                                                setFormasDePagamentosSelecionadas([
                                                                    ...formasDePagamentosSelecionadas,
                                                                    [item, document.getElementById("valorPagoModal" + item.formaPagamento).value, document.getElementById("valorParcelamentoModal" + item.formaPagamento).value ]
                                                                ]);
                                                                setShowModalFormaDePagamento(false);
                                                                    
                                                            }
        
                                                        }else{
        
                                                            if(document.getElementById("valorPagoModal" + item.formaPagamento).value == 0){
        
                                                                setErrorMsg('Nenhum valor foi informado!');
                                                                setShowModalErro(true);
            
                                                            }else{
        
                                                                if(item.formaPagamento == "FIDELIDADE"){
        
                                                                    if(idClienteFidelidade == null || idClienteFidelidade == undefined || idClienteFidelidade == 0 || idClienteFidelidade == ""){
        
                                                                        // setShowModalFormaDePagamento(false);
                                                                        // setShowModalCliente(true);
        
                                                                        setErrorMsg('Não foi informado nenhum cliente fidelidade!');
                                                                        setShowModalErro(true); 
        
                                                                    }else{
        
                                                                        setInfoFormaPagamentoFidelidade({
                                                                            valor: document.getElementById("valorPagoModal" + item.formaPagamento).value,
                                                                            item: item
                                                                        });
                                                                        setShowModalFormaDePagamento(false);
                                                                        setShowModalPagamentoFidelidade(true);
        
                                                                    }
        
                                                                }else{
                                                                    
                                                                    //formasPagSelecDinheiros.push( [item, document.getElementById("valorPagoModal" + item.formaPagamento).value ] );
        
                                                                    setFormasPagSelecDinheiros([
                                                                        ...formasPagSelecDinheiros,
                                                                        [item, document.getElementById("valorPagoModal" + item.formaPagamento).value ]
                                                                    ])
        
                                                                    let sub = valorPagoTotalDinheiro + parseFloat( document.getElementById("valorPagoModal" + item.formaPagamento).value );
                                                                    
                                                                    setValorPagoTotalDinheiro(sub);
                                                                    setShowModalFormaDePagamento(false);
        
                                                                }
        
                                                            }
        
                                                        }
        
                                                    }}
                                                    >Adicionar</Button>
                                                </div>
                                            
                                            </Col>
        
                                        </Row>
        
                                        <Row style={{marginTop: "2%"}}><hr></hr></Row>
        
                                    </Container>
        
                                }
        
                                
                            })
                        :
                            <Container style={{marginBottom: "1%"}}>
                                <h3 style={{textAlign: "center", color: "red"}}>
                                    Nenhuma forma de pagamento foi carregada, contate o suporte imediatamente !!!
                                </h3>
                                {
                                    JSON.stringify(user)
                                }
                            </Container>
                    }

                </Modal.Body>
            </Modal>

            {/* Modal add Parcelas Fidelidade */}
            <Modal
            show={showModalPagamentoFidelidade}
            onHide={() => {
                setShowModalPagamentoFidelidade(false);
                setFormasPagSelecFidelidade([]);
            }}
            keyboard={false}
            id="modalAddParcelasFidelidade"
            centered
            size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title><h1><LuPlusCircle />Adicionar parcelas fidelidade</h1> </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-light'>
                    <Container>

                        {infoFormaPagamentoFidelidade != null && infoFormaPagamentoFidelidade != undefined
                        
                        ?<Container>
                            <Row>
                                <Col>
                                    <FloatingLabel
                                    label="Valor: "
                                    className="mb-3"
                                    >
                                        <Form.Control 
                                            className="mb-3"
                                            type="number" step="0.01"
                                            defaultValue={infoFormaPagamentoFidelidade != null && infoFormaPagamentoFidelidade != undefined ? infoFormaPagamentoFidelidade.valor : 0}
                                            disabled
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                        
                                    <FloatingLabel
                                    label="Parcelar:"
                                    >
                                        <Form.Select 
                                        aria-label="Parcelamento"
                                        id='qtdParcelamentoFidelidadeParcelamentoTotal'
                                        value={qtdParcelasFidelidade}
                                        onChange={(e) => {
                                            setQtdParcelasFidelidade(e.target.value);
                                            
                                        }}
                                        >
                                        {
                                            (
                                                () => {
                                                    let li = [];
                                                    if(infoFormaPagamentoFidelidade.item.qtdParcelamentoMaximo === 0){
                                                        li.push(<option key={1} value={(1)}>{(1)+"x"}</option>);
                                                    }else{
                                                        for (let i = 1; i <= infoFormaPagamentoFidelidade.item.qtdParcelamentoMaximo; i++) {
                                                            li.push(<option key={infoFormaPagamentoFidelidade.item.formaPagamento + i} value={(i)}>{(i)+"x"}</option>);
                                                        }
                                                    }
                                                    
                                                    return li;
                                                }
                                            )()
                                        }
                                        </Form.Select>
                                    </FloatingLabel>

                                </Col>
                            </Row>
                            <hr></hr>
                            {formasPagSelecFidelidade.map((item, index) => {
                                console.log(formasPagSelecFidelidade);
                                console.log(item);

                                return <Row key={index}>
                                    <Col>
                
                                        <FloatingLabel
                                        label={"Valor da " + (index + 1) + "º parcelas: "}
                                        className="mb-3"
                                        >
                                            <Form.Control 
                                                className="mb-3"
                                                type="number" step="0.01"
                                                value={item.valorParcela}
                
                                                onChange={(e) => {
                
                                                    setFormasPagSelecFidelidade(prevState => {
                                                        const updatedArray = [...prevState]; 
                                                        updatedArray[index] = {
                                                            ...updatedArray[index], 
                                                            valorParcela: parseFloat(e.target.value)
                                                        };
                                                        return updatedArray;
                                                    });
                
                                                }}
                                            />
                                        </FloatingLabel>
                
                                    </Col>
                                    <Col>
                
                                    <Form.Control
                                        type="date"
                                        name="datepic"
                                        min={new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString()}
                                        value={new Date(item.dataVencimento).getFullYear().toString() + "-" + ( (new Date(item.dataVencimento).getMonth() + 1) <= 9 ? ("0" + (new Date(item.dataVencimento).getMonth() + 1).toString()) : new Date(item.dataVencimento).getMonth() + 1).toString()  + "-" + ( (new Date(item.dataVencimento).getDate()) <= 9 ? ("0" + new Date(item.dataVencimento).getDate().toString()) : (new Date(item.dataVencimento).getDate().toString()) ).toString()}
                                        onChange={(e) => {

                                            // let arrayTemp = formasPagSelecFidelidade;

                                            // arrayTemp[index].dataVencimento = e.target.value;

                                            // setFormasPagSelecFidelidade(arrayTemp);

                                            setFormasPagSelecFidelidade(prevState => {
                                                const updatedArray = [...prevState]; 
                                                updatedArray[index] = {
                                                    ...updatedArray[index], 
                                                    dataVencimento: e.target.value
                                                };
                                                return updatedArray;
                                            });

                                        }}
                                    />
                
                                    </Col>
                                </Row>
                            })}

                        </Container>
                        
                        :null                            
                        }

                        <Row>
                            <Col
                            
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            
                            >
                                <Button
                                    variant="primary"
                                    onClick={() => {

                                        let valorTotalPagoFidelidade = 0;

                                        formasPagSelecFidelidade.map((item) => {
                                           
                                            valorTotalPagoFidelidade += item.valorParcela;
                                            
                                        });

                                        if(valorTotalPagoFidelidade == infoFormaPagamentoFidelidade.valor){

                                            // let arrayTemp = formasDePagamentosSelecionadas;
                                            // arrayTemp.push( [infoFormaPagamentoFidelidade.item, parseFloat(infoFormaPagamentoFidelidade.valor), document.getElementById("qtdParcelamentoFidelidadeParcelamentoTotal").value ] );

                                            setShowModalFormaDePagamento(false);
                                            setShowModalPagamentoFidelidade(false);

                                            
                                            setFormasDePagamentosSelecionadas([
                                                ...formasDePagamentosSelecionadas,
                                                [infoFormaPagamentoFidelidade.item, parseFloat(infoFormaPagamentoFidelidade.valor), document.getElementById("qtdParcelamentoFidelidadeParcelamentoTotal").value ] 
                                            ]);

                                        }else{

                                            setErrorMsg('O Valor informado da parcelas difere do valor total da forma de pagamento!');
                                            setShowModalErro(true);

                                        }
                                        
                                    }}
                                >
                                    Registrar
                                </Button>
                            </Col>
                        </Row>


                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Cupom Fiscal ou não fiscal */}
            <Modal
            show={showModalCupomFicalOuNaoFiscal}
            backdrop="static"
            onHide={() => {}}
            keyboard={false}
            id="modalCupomFicalOuNaoFiscal"
            centered
            >
                <Modal.Header>
                    <Modal.Title>
                        <h4>Venda gerada com sucesso!</h4>
                        <h4>Cupom N.º {cupom}</h4>
                        <h4>Tipo de cupom: </h4> 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadNfce == false
                    ?<Container>
                        <Row>
                            <Col>
                                <div className="d-grid gap-2">

                                    <Button
                                    variant="primary"
                                    onClick={() => {

                                        setShowModalSucessoCupomFiscal(true);
                                        setShowModalCupomFicalOuNaoFiscal(false);

                                    }}
                                    >Ticket Venda</Button>

                                </div>
                            </Col>
                            
                            <Col>

                                <div className="d-grid gap-2">
                                    <Button
                                    variant="primary"
                                    onClick={async () => {

                                        setLodalNfce(true);
                                
                                        let data = {
                                            idOrganizacao: user.operador.idOrganizacao,
                                            nomeOrganizacao: user.operador.filialSelecionada.nomeOrganizacao,
                                            idFilial: user.operador.idFilial,
                                            nomeFilial: user.operador.filialSelecionada.empresaRazaoSocial,
                                            idOperador: user.operador.codOperador,
                                            nomeOperador: user.operador.nomeOperador,
                                            numeroCupom: cupom,
                                            numeroCaixa: user.operador.numCaixa,
                                            tipo: "NF",
                                            userWS: global.USER_WS,
                                            senhaWS: global.PASS_WS,
                                            cnpjEmit: user.operador.filialSelecionada.empresaCNPJ,
                                            cpf_cnpj: cpfcnpjCliente,
                                            email: email,
                                            numeroWpp: numWpp
                                            
                                        };

                                        //console.log(JSON.stringify(data));
                                
                                        await axios.post(global.URL_BASE + global.CAMINHO_EMITIRNFCe, data)
                                        .then((response) => {
                                
                                            let resultData = response.data;

                                            if(resultData.code == 0){
                                                
                                                // setState({
                                                //     showModalLoad: false,
                                                //     showModalSucessFinal: true,
                                                //     showModalCupomFicalOuNaoFiscal: false,
                                                //     loadNfce: false,
                                                //     msgSucessoFinal: "NFC-e emitida com sucesso!",
                                                //     nfceBase64: resultData.content
                                                // });

                                                setNfceBase64(resultData.content);
                                                setShowModalSucessFinal(true);
                                                setMsgSucessoFinal("NFC-e emitida com sucesso!");

                                            }else{

                                                setShowModalErroNFCe(true);

                                            }
                                            
                                        })
                                        .catch(function (error) {
                                            Sentry.captureException(error);

                                            setShowModalErroNFCe(true);
                                            
                                        })
                                        .finally(() => {

                                            setShowModalCupomFicalOuNaoFiscal(false);
                                            setLodalNfce(false);
                                            setShowModalLoad(false);
                                        });

                                    }}
                                    >Cupom fiscal (NFC-e)</Button>

                                </div>

                            </Col>
                        </Row>
                    </Container>
                    :<Container>
                        <Row style={{alignContent: "center", justifyContent: "center", alignItems: "center"}}>
                            <Col>
                                <Spinner animation="border" role="status" >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </Col>
                        </Row>
                    </Container>
                    }
                    
                    
                </Modal.Body>
            </Modal>

            {/* Modal enviar por email  */}
            <ModalEmail 
            showModal={showModalEmail} 
            setShowModal={ (e) => setShowModalEmail(e) } 
            funcPos={
                () => {

                    setShowModalCupomFicalOuNaoFiscal(true);
                    setShowModalEmail(false);

                }
            } 
            Email={email} 
            setEmail={ (e) => setEmail(e) } 
            Wpp={numWpp}
            setWpp={(e) => setNumWpp(e)}
            cupom={cupom}
            />

            {/* Modal cnpf/cnpj cliente */}
            <Modal
            show={showModalCpfCnpj}
            backdrop="static"
            keyboard={false}
            id="modalCpfCnpj"
            centered
            size='lg'
            >
                <Modal.Header>
                    <Modal.Title><h1>CPF/CNPJ para NF Paulista (opcional): </h1> </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Container>

                        <Row>
                            <Col>
                            
                                <FloatingLabel
                                    controlId="floatingInputCpfCnpj"
                                    label="Cpf/Cnpj"
                                    className="mb-3"
                                >
                                    <Form.Control 
                                        type="text"
                                        value={cpfcnpjCliente}
                                        onChange={(e) => {

                                            let v = e.target.value;

                                            v = v.replace(/\D/g, "")

                                            if (v.length <= 11) {
                                                v = v.replace(/(\d{3})(\d)/, "$1.$2")
                                                v = v.replace(/(\d{3})(\d)/, "$1.$2")
                                                v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
                                            } else if(v.length <= 14){ 
                                                v = v.replace(/^(\d{2})(\d)/, "$1.$2")
                                                v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
                                                v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
                                                v = v.replace(/(\d{4})(\d)/, "$1-$2")
                                            }else{
                                                v = cpfcnpjCliente;
                                            }
                                            
                                            setCpfCnpjCliente(v);
                                        
                                        }}
                                    />
                                </FloatingLabel>
                            
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                            
                                <div className="d-grid gap-2">
                                    <Button
                                    variant="danger"
                                    onClick={() => {

                                        setCpfCnpjCliente('');
                                        setShowModalCpfCnpj(false);

                                    }}
                                    >Enviar SEM CPF/CNPJ</Button>

                                </div>
                            
                            </Col>
                            <Col>

                                <div className="d-grid gap-2">
                                    <Button
                                    variant="primary"
                                    onClick={() => {

                                        //validação cpf/cnpj
                                        let validado = validaCpfCnpj(cpfcnpjCliente);

                                        if(validado){

                                            setShowModalCpfCnpj(false);

                                        }else{

                                            setErrorMsg('Cpf/Cnpj invalido');
                                            setShowModalErro(true);

                                        }

                                    }}
                                    >Enviar COM CPF/CNPJ</Button>

                                </div>

                            </Col>
                        </Row>
                    </Container>
                    
                </Modal.Body>
            </Modal>

            {/* Modal abertura caixa */}
            <Modal
            show={showModalAberturaCaixa}
            onHide={() => setShowModalAberturaCaixa(false)} 
            keyboard={false}
            id="modalAberturaCaixa"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><h1>Abertura de caixa</h1> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row style={{marginBottom: "5%"}}>
                            <Col style={{textAlign: "center", alignItems: "center"}}><h3>Informe o suprimento</h3></Col>
                        </Row>

                        <Row style={{marginBottom: "2%"}}>

                            <Col>
                                <FloatingLabel
                                controlId="floatingInput"
                                label="Suprimento: "
                                >
                                    <Form.Control
                                    placeholder="Suprimento"
                                    type="number" 
                                    step="0.01"
                                    min={0}
                                    value={suprimentoTroco}
                                    onChange={(e) => setSuprimentoTroco(e.target.value)}
                                    />
                                </FloatingLabel>
                            </Col>

                        </Row>

                        <Row >

                            <Col>
                                <div className="d-grid gap-2">
                                    <Button
                                    variant="success"
                                    onClick={() => {

                                        setShowModalLoad(true);

                                        let data = {

                                            idOrganizacao: user.operador.idOrganizacao,
                                            idFilial: user.operador.idFilial,
                                            idOperador: user.operador.idOperador,
                                            numCaixa: parseInt(user.operador.numCaixa),
                                            valorSuprimento: suprimentoTroco.toString()
                                
                                        };

                                        //console.log("DATA abertura de caixa", JSON.stringify(data));
                                        
                                        axios.post(global.URL_BASE + global.CAMINHO_ABERTURACAIXA, data)
                                        .then((response) => {
                                
                                            let resultData = response.data;
                                
                                            //console.log('Abertura de caixa Result', resultData);
                                
                                            if(resultData.code == 0){

                                                setShowModalAberturaCaixa(false);
                                                setShowMOdalSucess(true);
                                                setModalSucessMsg("Caixa aberto com sucesso");
                                                setCodAbertura(resultData.content);
                                                setControleAberturaFechamentoCaixa(true);

                                            }else{

                                                setErrorMsg('Ocorreu um erro ao abrir o caixa!');
                                                setShowModalErro(true);

                                            }
                                            
                                        })
                                        .catch(function (error) {
                                            Sentry.captureException(error);

                                            setErrorMsg('Ocorreu um erro ao abrir o caixa: ' + error.message);
                                            setShowModalErro(true);

                                        })
                                        .finally(() => {
                                            setShowModalLoad(false);
                                        });

                                    }}
                                    >Abrir Caixa</Button>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-grid gap-2">
                                    <Button
                                    variant="danger"
                                    onClick={() => {
                                        setShowModalAberturaCaixa(false);
                                    }}
                                    >Fechar</Button>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Erro NFC-e */}
            <Modal
            show={showModalErroNFCe}
            onHide={() => {window.location.reload()}}
            keyboard={false}
            backdrop="static"
            id="modalErroNFCe"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><h1><BiError /> Erro ao emitir NFCe!</h1></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        
                        <Row>
                            <b style={{textAlign: 'center', marginBottom: 5}}>
                                Venda gerada com sucesso! <br />
                                Cupom N.º {cupom}
                            </b>
                            <br />
                            <hr />
                            <p style={{color: "red", textAlign: 'center'}}>
                                Atenção!<br />
                                A venda já foi registrada, porém, 
                                houve um erro ao gerar a NFCe, não há necessidade de gerar uma nova venda para tentar fazer o cupom fiscal novamente, 
                                nesse caso, clique no menu e depois consulte as vendas e tente gerar um novo cupom fiscal
                            </p>
                            
                        </Row>
                        <Row>
                            <ReactToPrint
                                content={() => componentRef}
                                trigger={() => (
                                    <div className="d-grid gap-2">
                                        <Button
                                        variant="primary"
                                        >Imprimir Ticket</Button>

                                    </div>
                                )}
                                onAfterPrint={() => {
                                    //window.location.reload();
                                }}
                            />
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal sucesso NFC-e*/}
            <Modal
            show={showModalSucessFinal}
            onHide={() => {window.location.reload()}}
            keyboard={false}
            backdrop="static"
            id="modalSucess"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><h1><AiOutlineCheck /> Sucesso</h1> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Cupom N.º {cupom}</h4>
                    <h4>{msgSucessoFinal}</h4>
                    <Row style={{textAlign: "center"}}>
                        <h4>Venda gerada com sucesso!</h4>
                        
                    </Row>
                    <Row>
                        <div className="d-grid gap-2" style={{marginBottom: "1%"}}>
                            <Button
                            variant="primary"
                            onClick={async () => {

                                const linkSource = `data:application/pdf;base64,${nfceBase64}`;
                                const downloadLink = document.createElement("a");
                                const fileName = "file.pdf";

                                downloadLink.href = linkSource;
                                downloadLink.download = fileName;
                                downloadLink.click();

                            }}
                            >Baixar PDF</Button>
                        </div>
                    </Row>

                    <Row>

                        <div className="d-grid gap-2" style={{marginBottom: "1%"}}>
                            <Button
                            variant="primary"
                            onClick={() => {

                                const byteCharacters = atob(nfceBase64);
                                const byteNumbers = new Array(byteCharacters.length);
                                for (let i = 0; i < byteCharacters.length; i++) {
                                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                                }
                                const byteArray = new Uint8Array(byteNumbers);
                                const blob = new Blob([byteArray], { type: 'application/pdf' });
                                const blobUrl = URL.createObjectURL(blob);

                                const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

                                if (isMobile) {
                                    // Cria um link de download temporário
                                    const link = document.createElement('a');
                                    link.href = blobUrl;
                                    //link.download = 'documento.pdf';
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                } else {
                                    printJS({
                                        printable: nfceBase64,
                                        type: "pdf",
                                        base64: true
                                    });
                                }


                            }}
                            >Imprimir/Visualizar</Button>
                        </div>

                    </Row>
                    
                </Modal.Body>
            </Modal>

            {/* Modal sucesso Cupom Fiscal*/}
            <Modal
            show={showModalSucessoCupomFiscal}
            onHide={() => {window.location.reload()}}
            keyboard={false}
            backdrop="static"
            id="modalSucess"
            centered
            onShow={() => {
                if((email != undefined && email != null && email != "") || (numWpp != undefined && numWpp != null && numWpp != "") ){

                    setShowModalLoad(true);

                    const element_cupom = document.getElementById('containerPdf');

                    let data = {
                        html: element_cupom.outerHTML,
                        email: email,
                        numCupom: cupom,
                        razaoSocial: user.operador.filialSelecionada.empresaRazaoSocial,
                        idOrg: user.operador.idOrganizacao,
                        idFilial: user.operador.idFilial,
                        numeroWppEnvio: numWpp
                    };
            
                    axios.post(global.URL_BASE + global.CAMINHO_ENVIAREMAILNAOFISCAL, data)
                    .then((response) => {
            
                        let resultData = response.data;

                        if(resultData.code != 0){

                            Sentry.captureException(response);

                        }
                        
                    })
                    .catch( (error) => {
                        Sentry.captureException(error);
                    })
                    .finally(() => {
                        setShowModalLoad(false);
                    });
                }
            }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4>Venda gerada com sucesso!</h4>
                        <h4>Cupom N.º {cupom}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{textAlign: "center"}}>
                        
                    </Row>

                    <Row>

                        <Col>
                            {/*                             
                            <div className="d-grid gap-2">
                                <Button
                                variant="primary"
                                onClick={() => {

                                    document.getElementById("btnImprimirTicket").click();

                                }}
                                >Enviar cupom por email</Button>

                            </div> */}
                            
                            <ReactToPrint
                                content={() => componentRef}
                                trigger={() => (
                                    <div className="d-grid gap-2">
                                        <Button
                                        variant="primary"
                                        >Imprimir/Visualizar</Button>
                                    </div>
                                )}
                                onAfterPrint={() => {
                                    //window.location.reload();
                                }}

                                pageStyle={'@media print { body { -webkit-print-color-adjust: exact; } @page { size: A5; margin: 200mm !important } }'}
                                // @page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }

                            />
                        
                        </Col>

                    </Row>
                    
                </Modal.Body>
            </Modal>

            {/* Modal Confirmação */}
            <ModalConfimacao 
                showModal={showModalConfirmarAcao}
                setShowModal={(e) => setShowModalConfirmarAcao(e) }
                funcPos={funcModalConfirmacao}
                msgModalConfirmarAcao={msgModalConfirmarAcao}
            />

            <ModalSelecionarVendedor
                showModal={showModalSelecionarVendedor}
                setShowModal={setShowModalSelecionarVendedor}
                setVendedorSelecionado={setVendedorSelecionado}
                vendedores={vendedores}
                funcPos={funcPosModalSelecionarVendedor}
            />

            {/* Modal loading */}
            <ModalLoad showModal={showModalLoad} />

            {/* Modal erro */}
            <ModalErro showModal={showModalErro} setShowModal={ (e) => setShowModalErro(e) }msg={errorMsg} permiteFechar={naoPermiteFecharModalErro} />

            {/* Modal sucesso*/}
            <ModalSucesso showModal={showModalSucess} 
            setShowModal={ (e) => setShowMOdalSucess(e) } msg={ModalSucessMsg} />

            {/* Top bar */}
            <Navbar 
            expand={false} 
            className='rounded'
            style={{width: "100%", backgroundColor: "#dddddd"}}
            >
                <Container fluid>

                    <ModalAlterarSenhaOperador showModal={showModalAlterarSenha} setShowModal={ (e) => setShowModalAlterarSenha(e) } user={user} />

                    <Navbar.Brand>
                        <h2 style={{wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal'}}>Bem-vindo{", " + user.operador.nomeOperador} ! </h2>
                        <h6 style={{wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal'}}>Caixa {user.operador.numCaixa} Operador {user.operador.idOperador} </h6>
                        <h6 style={{wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal', color: 'black'}}>
                            {vendedorSelecionado != null && vendedorSelecionado != '' ? 
                                <h6 style={{wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal'}}>
                                    <Button 
                                        onClick={() => setVendedorSelecionado(null)}
                                        style={{padding: "0px", margin: "0px", marginBottom: 5, backgroundColor: "transparent", border: "none", color:'black',  justifyContent: 'center'}}
                                    >
                                        {/* <LuPlusCircle size={20} color='#0a0a0a'/> */}
                                        <IoMdClose size={22} color='#0a0a0a' />
                                        {/* {' X'} */}
                                    </Button>
                                    Vendedor: {vendedorSelecionado.idVendedor} - {vendedorSelecionado.nomeVendedor}
                                </h6>
                            : null}
                        </h6>
                        <h6 style={{wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal', color: 'black'}}>
                            {cpfcnpjCliente != null && cpfcnpjCliente != '' ? <h6 style={{wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal'}}> CPF/CNPJ: {cpfcnpjCliente} </h6> : null}
                            {(cpfcnpjCliente == null || cpfcnpjCliente == '') && (produtos != null ? produtos.length <= 0 : true ) ?
                                <Button 
                                    onClick={() => setShowModalCpfCnpj(true)}
                                    style={{padding: "0px", margin: "0px", backgroundColor: "transparent", border: "none", color:'black',  justifyContent: 'center'}}
                                >
                                    <LuPlusCircle size={20} color='#0a0a0a'/>
                                    {' DOC '} 
                                </Button>
                            : null}
                        </h6>
                                 

                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${false}`} />
                    <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${false}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
                    placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                <h1>Caixa{" " + user.operador.numCaixa}</h1>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <hr />
                        <Offcanvas.Body>

                            <Nav.Item
                            
                            style={{

                                marginTop: "1%",
                                marginBottom: "1%",

                            }}
                            
                            >
                                <div className="d-grid gap-2">
                                    <Button
                                    variant={"primary"}
                                    onClick={ () => {

                                        window.location.href = "/listavendas";

                                    }}
                                    
                                    ><h5>Consultar vendas já realizadas</h5></Button>
                                </div>
                            </Nav.Item>

                            <Nav.Item
                            
                            style={{

                                marginTop: "1%",
                                marginBottom: "1%",

                            }}
                            
                            >
                                <div className="d-grid gap-2">
                                    <Button
                                    variant={"primary"}
                                    onClick={ () => {

                                        window.location.href = "/listanfces";

                                    }}
                                    
                                    ><h5>Consultar notas emitidas</h5></Button>
                                </div>
                            </Nav.Item>

                            <hr />
                            
                            <Nav.Item
                            
                            style={{

                                marginTop: "1%",
                                marginBottom: "1%",

                            }}
                            
                            >
                                <div className="d-grid gap-2">
                                    <Button
                                    variant={controleAberturaFechamentoCaixa === false ? "success" : "danger"}
                                    onClick={ () => {

                                        if(controleAberturaFechamentoCaixa === false){

                                            setShowModalAberturaCaixa(true);

                                        }else{

                                            //fechamento caixa

                                            if(produtos != null && produtos.length > 0){

                                                setErrorMsg("Não é possivel fechar o caixa quando houver produtos!");
                                                setShowModalErro(true);

                                            }else{

                                                let funcFechamentoCaixa = async () => {

                                                    let data = {
                
                                                        idOrganizacao: user.operador.idOrganizacao,
                                                        idFechamentoAbertura: codAbertura,
                                            
                                                    };
                
                                                    //console.log("DATA fechamento de caixa", JSON.stringify(data));
                                                    
                                                    await axios.post(global.URL_BASE + global.CAMINHO_FECHAMENTOCAIXA, data)
                                                    .then((response) => {
                                            
                                                        let resultData = response.data;
                                            
                                                        //console.log('Fechamento de caixa Result', resultData);
                                            
                                                        if(resultData.code == 0){
                                            
                                                            setCodAbertura(0);
                                                            setControleAberturaFechamentoCaixa(false);
                                                            setModalSucessMsg("Caixa fechado com sucesso");
                                                            setShowMOdalSucess(true);

                
                                                        }else{

                                                            setErrorMsg("Ocorreu um erro ao fechar o caixa.");
                                                            setShowModalErro(true);
                
                                                        }
                                                        
                                                    })
                                                    .catch((error) => {
                                                        Sentry.captureException(error);

                                                        setErrorMsg("Ocorreu um erro ao fechar o caixa: " + error.message);
                                                        setShowModalErro(true);

                                                    })
                                                    .finally(() => {
                                                        setFuncModalConfirmacao(() => null);
                                                        setShowModalConfirmarAcao(false);
                                                    });
                                                    
                                                }

                                                setFuncModalConfirmacao(funcFechamentoCaixa);
                                                setMsgModalConfirmarAcao("Deseja realmente fechar o caixa ?");
                                                setShowModalConfirmarAcao(true);

                                            }

                                        }

                                    }}
                                    
                                    ><h5>{controleAberturaFechamentoCaixa === false ? "Abrir Movimento do Operador" : "Fechar Movimento do Operador"}</h5></Button>
                                </div>
                            </Nav.Item>

                            <hr />

                            <Nav.Item

                            style={{
                                marginBottom: "1%",
                            }}

                            >
                                
                                <div className="d-grid gap-2">
                                    <Button onClick={
                                        () => {
                                            setShowModalAlterarSenha(true);
                                        }
                                    }>
                                        <h5>Alterar Senha</h5>
                                    </Button>
                                </div>

                            </Nav.Item>


                            <Nav.Item

                            style={{
                                marginBottom: "1%",
                            }}

                            >
                                
                                <div className="d-grid gap-2">
                                    <Button
                                    variant='danger'
                                    onClick={
                                        () => {
                                            setShowModalDeslogar(true);
                                        }
                                    }>
                                        <h5>Sair</h5>
                                    </Button>
                                </div>

                            </Nav.Item>

                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>

            {
                //condição ao abrir caixa
                controleAberturaFechamentoCaixa === true
                ?<Container style={{
                    marginTop: "1%"
                }} fluid>

                    {/*Adicionar clientes e buscar produto */}
                    <Row>

                        <Col
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            style={{marginBottom: "2%"}}
                        >
                            <div className="d-grid gap-2">
                                <Button
                                variant="primary"
                                onClick={() => {

                                    if( controleCupomJaIniciado === false || (idClienteFidelidade != null && idClienteFidelidade != undefined && idClienteFidelidade != "" && idClienteFidelidade != 0) ){
                                        setShowModalCliente(true);
                                    }else{
                                        setErrorMsg("Não é possivel adicionar um novo cliente em um cupom que já foi iniciado!");
                                        setShowModalErro(true);
                                    }
                                    

                                }}
                                
                                ><h4><LuPlusCircle size={45}/> Adicionar Cliente</h4></Button>
                            </div>
                        </Col>

                        <Col 
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            style={{textAlign: "center", justifySelf: "center", marginBottom: "2%"}}
                        >
                            <div className="d-grid gap-2">
                                <Button
                                onClick={() => {

                                    setShowModalAdicionarProdutos(true);

                                }}>
                                    <h4><CiSearch size={45}/>Buscar Produtos</h4>
                                </Button>
                            </div>
                                    
                        </Col>

                    </Row>

                    {/* exibi nome do cliente  */}
                    {nomeClienteFidelidade != null && nomeClienteFidelidade != undefined && nomeClienteFidelidade != "" ?
                        <Row>
                            <Col
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                style={{textAlign: "center", justifySelf: "center"}}
                            >

                                <FloatingLabel
                                    controlId="floatingnomeView"
                                    label="Nome do cliente: "
                                    className="mb-3"
                                >
                                    <Form.Control 
                                        type="text"
                                        defaultValue={nomeClienteFidelidade} 
                                        // onChange={(e) => {
                                            
                                        //     e.target.value = nomeClienteFidelidade;

                                        // }}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                style={{textAlign: "center", justifySelf: "center"}}
                            >

                                <FloatingLabel
                                    controlId="floatingNumCupomView"
                                    label="Cupom:"
                                    className="mb-3"
                                >
                                    <Form.Control 
                                        type="text"
                                        defaultValue={cupom == null ? "" : cupom} 
                                        // onChange={(e) => {
                                            
                                        //     e.target.value = cupom;

                                        // }}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                    :
                        <Row>
                            <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                style={{textAlign: "center", justifySelf: "center"}}
                            >

                                <FloatingLabel
                                    controlId="floatingNumCupomView"
                                    label="Numero do cupom: "
                                    className="mb-3"
                                >
                                    <Form.Control 
                                        type="text"
                                        defaultValue={cupom} 
                                        // onChange={(e) => {
                                            
                                        //     e.target.value = cupom;

                                        // }}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                    }
                    {/*CodProdutos*/}
                    <Row>
                        <Col
                        xs={12}
                        sm={7}
                        md={8}
                        lg={9}
                        xl={10}
                        >
                            
                            <FloatingLabel
                                controlId="floatingInputCodProduto"
                                label="Cód. Produto"
                                className="mb-4"
                            >
                                <Form.Control 
                                    type="text" 
                                    value={codProd} 
                                    onChange={(e) => {
                                        setCodProd(e.target.value);
                                    }}
                                />
                            </FloatingLabel>
                        
                        </Col>
                        <Col
                        >
                            <div className="d-grid gap-2">
                                <Button
                                onClick={handleBuscarProdutoCod}>
                                <h4>Buscar</h4>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <MobileView>
                        <Row style={{marginBottom: 5, marginTop: 5}}>
                            <div className="d-grid gap-2">
                                <Button onClick={() => {
                                    setCodProd("");
                                    setFuncPosLeitor((barcode) => (barcode) => {
                                        setCodProd(barcode);
                                    });
                                    setIsScanning(true);
                                }}>
                                    <FaCamera  size={25} />
                                </Button>
                            </div>
                        </Row>
                    </MobileView>
                    
                    {/* Nome produto. */}
                    <Row style={{marginTop: "1%"}}>
                        <Col><h3 >Produto: <p style={{color: "red"}}>{nomeProdutoSelecionado}</p></h3></Col>
                    </Row>
                    {/* Adicionar produto a sacola de compras. */}
                    <Row style={{marginTop: "1%"}}>
                        <Col 
                        xs={5}
                        sm={5}
                        md={5}
                        lg={5}
                        xl={5}
                        
                        >
                        
                            <FloatingLabel
                                controlId="floatingInputQuant"
                                label="Qtd"
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="number" step="0.01"
                                    value={qtdSelecionada} 
                                    onChange={(e) => {
                                        if(e.target.value < 0){
                                            setQtdSelecionada(1);
                                        }else{
                                            setQtdSelecionada(parseFloat(e.target.value));
                                        }
                                    }}
                                />
                            </FloatingLabel>
                        
                        </Col>
                        <Col  
                        style={{textAlign: "center", justifySelf: "center"}}
                        xs={2}
                        sm={2}
                        md={1}
                        lg={1}
                        xl={1}
                        ><h1>X</h1></Col>
                        <Col
                        xs={5}
                        sm={5}
                        md={5}
                        lg={5}
                        xl={5}
                        >
                            
                            <FloatingLabel
                                controlId="floatingInputPreco"
                                label="R$ "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type={'number'}
                                    step="0.01"
                                    value={ (preco == null || preco == undefined || preco == "" ? "" :  preco ) }
                                    onChange={(e) => {
                                        try {
                                            setPreco(e.target.value);
                                            //setPreco(parseFloat(e.target.value));
                                            // if(!isNaN(e.target.value)){
                                            //     setPreco(e.target.value);
                                            // }else{
                                            //     e.target.value = preco;
                                            // }
                                        } catch (error) {
                                            setPreco('');
                                        }
                                    }}

                                    // onDoubleClick={() => {
                                        
                                    //     if(user.operador.permiteExclusaoCupomFcx == 0){

                                    //         setFuncModalAdminSenha(() => setControleDisabledPreco(false));
                                    //         setShowModalAdminSenha(true);

                                    //     }else{

                                    //         setControleDisabledPreco(false)

                                    //     }

                                    // }}
                                />
                            </FloatingLabel>
                        
                        </Col>
                        <Col
                        style={{textAlign: "center", justifySelf: "center"}}
                        xs={12}
                        sm={12}
                        md={1}
                        lg={1}
                        xl={1}
                        >
                            <div className="d-grid gap-2">
                                <Button onClick={() => {

                                    console.log(produtoSelecionado);

                                    const funcAdd = () => {
                                        try{

                                            setShowModalLoad(true);
                                            //setControleDisabledPreco(true);
        
                                            if(produtoSelecionado === null || produtoSelecionado === undefined){
                                                throw new Error("Nenhum item selecionado");
                                            }
        
                                            if(qtdSelecionada === null || qtdSelecionada === undefined || qtdSelecionada == 0 || qtdSelecionada === ''){
                                                throw new Error("Nenhuma quantidade selecionada");
                                            }
    
                                            if(preco === null || preco == undefined || preco == 0 || preco == '' || isNaN(preco)){
                                                throw new Error("Nenhum preço selecionada");
                                            }
            
                                            //console.log(cupom);
    
    
                                            let precoDecimal = parseFloat(preco);
        
                                            let data = {
                                                idOrganizacao: user.operador.idOrganizacao,
                                                idFilial: user.operador.idFilial,
                                                numeroCaixa: user.operador.numCaixa,
                                                usuarioWS: global.USER_WS,
                                                senhaWS: global.PASS_WS,
                                                idPdv: user.operador.numCaixa,
                                                idOperador: user.operador.idOperador,
                                                numeroCupomFiscal: cupom.toString(),
                                                codigoBarras: produtoSelecionado.codigoBarras,
                                                qtdDoItem: qtdSelecionada,
                                                precoDoItem: precoDecimal,
                                                tipoDaAliquota: produtoSelecionado.tipoAliquota,
                                                valorAliquota:  parseFloat(produtoSelecionado.valorAliquota).toString(),
                                                sequencia: sequenciaItem,
                                                //dataVenda: new Date(),
                                                codigoProdutoRetaguarda: produtoSelecionado.codigoProdutoRetaguarda,
                                                cpfcnpj: cpfcnpjCliente,
                                                tipoCupom: 'NF',
                                                pNCM: produtoSelecionado.ncm,
                                                aliquotaNCMFederal: 0,
                                                aliquotaNCMEstadual: 0,
                                                pcOrigem: "FCXCLOUD",
                                                ipOrigem: ipUser,
                                                macAdressOrigem: macAddresses,
                                                idSequenciaFilho: produtoSelecionado.idSeqenciaProdutoFilho.toString(),
                                                idAtributosFilho: '',
                                                configAtual: '',
                                                sequenciaVariacaoPacote: 0,
                                                qtdUnidadePacote: 0,
                                                idClientePontuacao: idClienteFidelidade == null || idClienteFidelidade == "" ? 0 : idClienteFidelidade,
                                                docClientePontuacao: cpfcnpjClientePesquisaModal == null ? '' : cpfcnpjClientePesquisaModal,
                                                nomeClientePontuacao: nomeClienteFidelidade == null ? '' : nomeClienteFidelidade,
                                                pontuacaoUtilizada: 0,
                                                qtdAtacado: parseFloat(produtoSelecionado.qtdMinimaAtacado),
                                                precoAtacado: parseFloat(produtoSelecionado.precoAtacado)
                                            };
        
                                            //console.log("REQUEST DATA", data);
        
                                            axios.post(global.URL_BASE + global.CAMINHO_ADICIONAVENDAITEM, data)
                                            .then( (response) => {
                                                
                                                let resultData = response.data;
        
                                                if(resultData.code === 0){
    
                                                    setProdutos([
                                                        ...produtos,
                                                        {
                                                            produto: produtoSelecionado,
                                                            qtd: qtdSelecionada,
                                                            preco: precoDecimal,
                                                            sequencia: sequenciaItem
                                                        }
                                                    ]);
    
                                                    setSequenciaItem(sequenciaItem + 1);
                                                    setProdutoSelecionado(null);
                                                    setNomeProdutoSelecionado("");
                                                    setPreco('');
                                                    setCodProd("");
                                                    setShowModalAdicionarProdutos(false);
                                                    //setControleDisabledPreco(true);
    
                                                    //cupom foi iniciado com sucesso
                                                    setControleCupomJaIniciado(true);
        
                                                }else{
    
                                                    setErrorMsg(resultData.msg);
                                                    setShowModalErro(true);
        
                                                }
                                                
                                            })
                                            .catch((error) => {
                                                Sentry.captureException(error);
    
                                                setErrorMsg("Ocorreu um erro ao adicionar o item ao pedido: " + error.message);
                                                setShowModalErro(true);
    
                                            })
                                            .finally(() => {
                                                setShowModalLoad(false);
                                            });
    
                                            
        
                                        }catch(err){
    
                                            Sentry.captureException(err);
    
                                            //console.log(err);
    
                                            setShowModalLoad(false);
                                            setErrorMsg(err.message);
                                            setShowModalErro(true);
        
                                        }
                                    }
                                    funcAdd();
                                    
                                }}>
                                    <LuPlusCircle size={45}/>
                                </Button>
                                
                            </div>
                            
                        
                        </Col>
                    </Row>
                    
                    <Row style={{marginTop: "2%"}}>

                        {(produtos != null && produtos.length > 0) ? (
                            <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                style={{marginBottom: "1%"}}
                            >

                                <Container>
                                    <Row>
                                        <Col
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        style={{textAlign: "left", justifyContent: "left", justifySelf: "left", justifyItems: "left"}}
                                        ><h1>Itens do cupom </h1></Col>
                                    </Row>
                                </Container>
                                
                                <hr />

                                <Container
                                
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                style={{marginBottom: "2%"}}

                                >
                                    
                                    {produtos.map((item, index) =>{

                                        return <Container key={Math.random() + Math.random() * Math.random() - Math.random()}>
                                            <Row
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            style={{marginBottom: "1%"}}
                                            >

                                                <Col
                                                xs={12}
                                                sm={11}
                                                md={11}
                                                lg={11}
                                                xl={11}
                                                >
                                                    {'#' + (index + 1) + ' - ' + item.produto.nomeProduto + ' - ' + item.preco.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) + ' x ' + item.qtd + ' = ' + (item.preco * item.qtd).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                    <br />
                                                    <p style={{ fontSize: "10px" }}>{'Código: ' + item.produto.codigoBarras}</p>
                                                </Col>
                                                <Col
                                                xs={12}
                                                sm={1}
                                                md={1}
                                                lg={1}
                                                xl={1}

                                                style={{textAlign: "center", justifyContent: "center", justifySelf: "center", justifyItems: "center"}}

                                                >

                                                    <div className="d-grid gap-2">
                                                        <Button
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        variant="danger"
                                                        onClick={() => {

                                                            let funcConfirmCancelamentoItem = () => {

                                                                let funcCacelamentoItem = () => {

                                                                    setShowModalLoad(true);
                
                                                                    let data = {
                                                                        idCupom: cupom.toString(),
                                                                        sequencia: item.sequencia,
                                                                        codProdutoRetaguarda: item.produto.codigoProdutoRetaguarda.toString(),
                                                                        idEmpresa: user.operador.idFilial,
                                                                        idOrganizacao: user.operador.idOrganizacao,
                                                                        usuarioWS: global.USER_WS,
                                                                        senhaWS: global.PASS_WS,
                                                                        tipoCupom: "NF",
                                                                        idOperadorAutorizaCancelamento: 0,
                                                                        qtd: item.qtd,
                                                                        numeroCaixa: user.operador.numCaixa,
                                                                        //dataVenda: item.dataVenda,
                                                                        ean: item.produto.codigoBarras
        
                                                                    };
                
                                                                    //console.log(item);
                                                                    //console.log(data);
                                                                    //console.log(JSON.stringify(data));
        
                                                                    axios.post(global.URL_BASE + global.CAMINHO_CANCELARITEMCUPOM, data)
                                                                    .then((response) => {
                
                                                                        let resultData = response.data;
        
                                                                        if(resultData.code === 0){
        
                                                                            //excluir item local
                                                                            // let produtosTemp = produtos.splice();
                                                                            // produtosTemp.splice(index, 1);

                                                                            // setProdutos(produtosTemp);

                                                                            setProdutos([
                                                                                ...produtos.slice(0, index),
                                                                                ...produtos.slice(index + 1)
                                                                            ]);
                                                                            setModalSucessMsg("Item excluido com sucesso!");
                                                                            setShowMOdalSucess(true);

                                                                            setShowModalConfirmarAcao(false);
                                                                            setFuncModalConfirmacao(() => () => {});
        
                                                                        }else{

                                                                            setErrorMsg(resultData.msg);
                                                                            setShowModalErro(true);
        
                                                                        }
                                                                        
                                                                    })
                                                                    .catch((error) => {
                                                                        Sentry.captureException(error);

                                                                        setErrorMsg("Ocorreu um erro o tentar excluir o item: " + error.message);
                                                                        setShowModalErro(true);

                                                                    })
                                                                    .finally(() => {
                                                                        setShowModalLoad(false);
                                                                    });

                                                                }

                                                                if(user.operador.permiteExclusaoCupomFcx == 0){

                                                                    setErrorMsg("Não você não tem permissão para realizar esta operação!");
                                                                    setShowModalErro(true);
                                                                    
                    
                                                                }else{
                    
                                                                    setFuncModalAdminSenha(() => funcCacelamentoItem);
                                                                    setShowModalAdminSenha(true);
                    
                                                                }                                                                

                                                            }

                                                            setFuncModalConfirmacao(() => funcConfirmCancelamentoItem);
                                                            setMsgModalConfirmarAcao("Deseja realmente excluir este item ?");
                                                            setShowModalConfirmarAcao(true);
                                                            
                                                        }}
                                                        
                                                        ><MdDelete style={{color: "black"}} size={25}/></Button>

                                                    </div>
                                                
                                                </Col>

                                            </Row>
                                        </Container>

                                    })}

                                </Container>

                                <hr />
                            
                            </Col>
                        ):
                        null}

                    </Row>

                    <div style={{marginRight: 45}}>
                        {desconto > 0 && desconto != null && desconto != undefined 
                        ?<Row
                            style={{display: "flex", justifyContent: "flex-end", fontWeight: "bold", fontSize: 20}}
                        >

                            <Col style={{display: "flex", justifyContent: "flex-end", fontWeight: "bold", fontSize: 20}}>
                            
                                <p>Desconto: {desconto.toFixed(2).replaceAll('.', ',')}</p>
                            
                            </Col>

                        </Row>  
                        :
                        null}
                        {troco !== null && troco > 0
                        ? <Row>
                            <Col style={{display: "flex", justifyContent: "flex-end", fontWeight: "bold", fontSize: 20}}>
                                <p>Troco: {troco.toFixed(2).replaceAll('.', ',')}</p>
                            </Col>
                        </Row>
                        : null}

                        <Row>
                            <Col style={{display: "flex", justifyContent: "flex-end", fontWeight: "bold", fontSize: 20}}>
                                <p>Total: {total.toFixed(2).replaceAll('.', ',')}</p>
                            </Col>
                        </Row>
                    </div>

                    <hr/>

                    <div style={{marginBottom: "1%"}}>
                        <Row >

                            <Col
                            >
                                
                                <h1>Pagamentos: </h1>
                            
                            </Col>

                            <Col style={{display: "flex", justifyContent: "flex-end"}}>
                                    
                                <div className="d-grid gap-2" style={{maxWidth: 250}}>
                                    <Button onClick={() => {
                                        if(total > 0){
                                            
                                            setShowModalFormaDePagamento(true);

                                        }else{
                                            
                                            setErrorMsg('Não há itens no cupom!');
                                            setShowModalErro(true);
                                            
                                        }
                                    }}>
                                        <LuPlusCircle size={30}/>
                                    </Button> 
                                </div>
                                
                            </Col>
                        </Row>
                        <hr />
                    </div>
                    

                    <Row>

                        {formasDePagamentosSelecionadas.map((item, index) => {
                            
                            //if(item[0].formaPagamento != 'DINHEIRO'){

                                return <Container key={Math.random() + Math.random() * Math.random() - Math.random()}>

                                <Row>
                                    <Col
                                    
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    
                                    >
                                    
                                        <h5>{item[0].formaPagamento}</h5>

                                    </Col>
                                </Row>

                                <Row style={{justifyContent: "left", justifyItems: "left"}}>

                                    <Form.Control 
                                        type="hidden"
                                        id={item[0].formaPagamento + "idFormaPagamento"}
                                        defaultValue={item[0].idFormaPagamento}
                                    />

                                    <Form.Check
                                        inline
                                        name={"group" + item[0].formaPagamento}
                                        type={'hidden'}
                                        id={"check" + item[0].formaPagamento}
                                        className="mb-3"
                                        disabled={true}
                                        defaultValue={true}
                                        checked={true}
                                    />
                                    <Col
                                    xs={4}
                                    sm={4}
                                    md={5}
                                    lg={5}
                                    xl={5}
                                    style={{display: "block"}}
                                    >

                                        <FloatingLabel
                                        controlId={"valorPago" + item[0].formaPagamento}
                                        label="Valor: "
                                        className="mb-3"
                                        >
                                            <Form.Control 
                                                className="mb-3"
                                                type="number" step="0.01"
                                                defaultValue={parseFloat(item[1]).toFixed(2)}
                                                disabled={true}
                                            />
                                        </FloatingLabel>

                                    </Col>
                                    <Col
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    style={{display: "block"}}
                                    >

                                        <FloatingLabel
                                        controlId={"valorParcelamento" + item[0].formaPagamento}
                                        label="Parcelar:"
                                        >
                                            <Form.Select aria-label="Parcelamento" disabled={true}>
                                                <option value={item[2]}>{item[2]+"x"}</option>
                                            </Form.Select>
                                        </FloatingLabel>

                                    </Col>

                                    <Col
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                    style={{textAlign: "center", justifyContent: 'flex-end', justifySelf: "center", justifyItems: 'end'}}
                                    >

                                        <div className="d-grid gap-2">
                                            <Button
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            variant="danger"
                                            onClick={() => {

                                                //console.log("TESTE FIDELIDADE RESET", item );

                                                //console.log("TESTE FIDELIDADE RESET", item[0].formaPagamento );

                                                if(item[0].formaPagamento == "FIDELIDADE"){
    
                                                    //console.log("TESTE FIDELIDADE RESET"

                                                    // this.setState({

                                                    //     info_forma_pagamento_fidelidade: null,
                                                    //     showModalPagamentoFidelidade: false,
                                                    //     qtdParcelasFidelidade: 1,
                                                    //     objsParcelasFidelidade: []

                                                    // });

                                                    setInfoFormaPagamentoFidelidade(null);
                                                    setShowModalPagamentoFidelidade(false);
                                                    setQtdParcelasFidelidade(1);
                                                    setFormasPagSelecFidelidade([]);

                                                }

                                                if(formasDePagamentosSelecionadas.length > 1){

                                                    let novoArray = [];

                                                    formasDePagamentosSelecionadas.forEach((element, indexLocal) => {
                                                    
                                                        if(indexLocal != index){
                                                            novoArray.push(element);
                                                        }

                                                    });

                                                    // this.setState({
                                                    //     troco: parseFloat((valorTotalPago - this.state.total).toFixed(2)),
                                                    //     formasDePagamentosSelecionadas: novoArray
                                                    // }, () => {
                                                    //     this.calcula();
                                                    // });

                                                    setFormasDePagamentosSelecionadas(novoArray);

                                                }else{

                                                    // this.setState({
                                                    //     troco: parseFloat((0 - this.state.total).toFixed(2)),
                                                    //     formasDePagamentosSelecionadas: []
                                                    // }, () => {
                                                    //     this.calcula();
                                                    // });

                                                    setFormasDePagamentosSelecionadas([]);

                                                }


                                            }}
                                            style={{padding:13}}
                                            >
                                                <MdDelete style={{color: "black"}} size={30}/>
                                            </Button>
                                        </div>

                                    </Col>

                                </Row>

                                <Row>
                                    <hr/>
                                </Row>

                                </Container>

                            //} 

                        })}

                        {formasPagSelecDinheiros.length > 0 ? renderFormasDePagamentoDinheiro() : null }
                        
                    </Row>

                    <Row
                    
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{marginBottom: "1%"}}

                    >

                        <Col>
                            
                            <div className="d-grid gap-2">
                                <Button
                                variant="primary"
                                onClick={() => {

                                    if(produtos.length === 0){

                                        setErrorMsg('Não há itens no cupom!');
                                        setShowModalErro(true);
                                        return;
                                    }

                                    
                                    let Totalpago = 0.00;
                                    formasDePagamentosSelecionadas.map((item) => {
                                        Totalpago += parseFloat(item[1]);
                                    });
                                    formasPagSelecDinheiros.map((item) => {
                                        Totalpago += parseFloat(item[1]);
                                    });
                                    if(Totalpago < total){
                                        setErrorMsg('Valor total difere do valor pago informado!');
                                        setShowModalErro(true);
                                        return;
                                    }

                                    const funcFinalizarCupom = (vendedorSelecionadoParam) => {

                                        console.log("VENDEDOR SELECIONADO: ", vendedorSelecionadoParam);

                                        setShowModalLoad(false);

                                        const funcFinalizarCompra = async () => {
                                            
                                            setShowModalLoad(true);
                                            setShowModalConfirmarAcao(false);
                                    
                                            let sequencia_pagamento = 1;
                                        
                                            //insere formas de pagamentos (não dinheiro)
                                            if(formasDePagamentosSelecionadas.length > 0 && formasDePagamentosSelecionadas != null){
                                                //console.log("formasDePagamentosSelecionadas", formasDePagamentosSelecionadas);
                                                await Promise.all(
                                                    await formasDePagamentosSelecionadas.map(async (item) => {
                                            
                                                        try{
                                            
                                                            sequencia_pagamento += 1;
                                            
                                                            let valorpago = parseFloat(item[1])
                                                            let quantParcelas = item[2]
                                            
                                                            let data = {
                                                                idFilial: user.operador.idFilial,
                                                                idCupom: cupom.toString(),
                                                                formaPagamento: item[0].formaPagamento,
                                                                valor: parseFloat(valorpago),
                                                                idCliente: idClienteFidelidade == null || idClienteFidelidade == undefined || idClienteFidelidade == "" ? 0 : idClienteFidelidade,
                                                                idOperador: user.operador.codOperador,
                                                                totalCupom: total,
                                                                idECF: user.operador.numCaixa,
                                                                numeroCaixa: user.operador.numCaixa,
                                                                codigoBandeira: 0,
                                                                numeroAutorizacao: '',
                                                                idOrganizacao: user.operador.idOrganizacao,
                                                                usuarioWS: global.USER_WS,
                                                                senhaWS: global.PASS_WS,
                                                                tipoCupom: 'NF',
                                                                idVendedor: vendedorSelecionadoParam != null && vendedorSelecionadoParam != undefined ? vendedorSelecionadoParam.idVendedor : 0,
                                                                desconto: desconto,
                                                                qtdParcelaFidelidade: quantParcelas,
                                                                dataPagamento: new Date(),
                                                                troco: troco != null ? troco : 0,
                                                                totalImpostoFederal: 0,
                                                                totalImpostoEstadual: 0,
                                                                sequenciaPagamento: sequencia_pagamento,
                                                                pcOrigem: "FCXCLOUD",
                                                                ipOrigem: ipUser,
                                                                macAdressOrigem: macAddresses,
                                                                entregaLocalDadosCliente: '',
                                                                entregaLocalIdCliente: 0,
                                                                orcamentoLocal: '',
                                                                orcamentoLocalID: 0,
                                                                idClientePontuacao: 0,
                                                                docClientePontuacao: '',
                                                                pontuacaoUtilizada: 0
                                                            };
                                            
                                                            console.log("TESTE ADD FORMAS PAGAMENTOS", data);
                                            
                                                            await axios.post(global.URL_BASE + global.CAMINHO_ATUALIZARFORMAPAGAMENTOCUPOM, data)
                                                            .then((response) => {
                                            
                                                                let resultData = response.data;
                                            
                                                                //console.log("RetornoForma pagamento: ", resultData);
                                            
                                                                if(resultData.code == 0){
                                            
                                                                    if( !((formasPagSelecFidelidade != null &&formasPagSelecFidelidade.length > 0) || (formasPagSelecDinheiros != null && formasPagSelecDinheiros.length > 0)) ){
                                                                        
                                                                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                                                        
                                                                        setShowModalConfirmarAcao(false);
                                                                        setShowModalEmail(true);
                                                                    }
                                            
                                                                }else{

                                                                    setErrorMsg(resultData.msg);
                                                                    setShowModalErro(true);

                                                                }
                                                                
                                                            })
                                                            .catch((error) =>  {

                                                                Sentry.captureException(error);

                                                                setErrorMsg('Ocorreu um erro ao realizar a finalização do pedido: ' + JSON.stringify(error));
                                                                setShowModalErro(true);

                                                                //console.log("ERROR: ", error);

                                                            });
                                            
                                                        }catch(err){

                                                            Sentry.captureException(err);
                                                            
                                                            setErrorMsg('Ocorreu um erro ao realizar a finalização do pedido, ao salvar a forma de pagamento: ' + JSON.stringify(err));
                                                            setShowModalErro(true);
                                                            setShowModalLoad(false);
                                            
                                                        }
                                                        
                                            
                                                    })
                                                );
                                            }

                                            //insere parcelas pagamento fidelidade
                                            formasPagSelecFidelidade.map((item, index) => {
                                    
                                                try{
                                    
                                                    let data = {
                                                    
                                                        strObjetoParcelamento: {
                                                            idFilial: user.operador.idFilial,
                                                            idOrganizacao: user.operador.idOrganizacao,
                                                            numeroParcela: index + 1,
                                                            valorParcela: item.valorParcela,
                                                            numeroCupom: cupom,
                                                            dataVencimento: new Date(item.dataVencimento).getFullYear().toString() + "-" + ( (new Date(item.dataVencimento).getMonth() + 1) <= 9 ? ("0" + (new Date(item.dataVencimento).getMonth() + 1).toString()) : new Date(item.dataVencimento).getMonth() + 1).toString()  + "-" + ( (new Date(item.dataVencimento).getDate()) <= 9 ? ("0" + new Date(item.dataVencimento).getDate().toString()) : (new Date(item.dataVencimento).getDate().toString()) ).toString(),
                                                            parcelaPaga: "False",
                                                            tipoCupom: 'NF'
                                                        },
                                                        idOrganizacao: user.operador.idOrganizacao,
                                                        idContaReceber: 0,
                                                        pcOrigem: "FCXCLOUD",
                                                        ipOrigem: ipUser,
                                                        macAdressOrigem: macAddresses,
                                                        numeroCaixa: user.operador.numCaixa
                                    
                                                    };
                                    
                                                    //console.log("TESTE ADD FORMAS PAGAMENTOS Fidelidades parcelas", data);
                                    
                                                    axios.post(global.URL_BASE + global.CAMINHO_CADASTRAPARCELASPAGAMENTOFIDELIDADE, data)
                                                    .then( (response) => {
                                    
                                                        let resultData = response.data;
                                    
                                                        if(resultData.code == 0){
                                    
                                                            if( !(formasPagSelecDinheiros != null && formasPagSelecDinheiros.length > 0) ){
                                                                
                                                                setShowModalConfirmarAcao(false);
                                                                setShowModalEmail(true);
                                                                
                                                            }
                                    
                                                        }else{

                                                            setErrorMsg(resultData.msg);
                                                            setShowModalErro(true);
                                                            setnaoPermiteFecharModalErro(true);
                                    
                                                        }
                                                        
                                                    })
                                                    .catch( (error) =>  {

                                                        Sentry.captureException(error);

                                                        setErrorMsg('Ocorreu um erro ao realizar a finalização do pedido: ' + JSON.stringify(error));
                                                        setShowModalErro(true);

                                                    })
                                                    .finally(() => {
                                                        setShowModalLoad(false);
                                                    });
                                    
                                                }catch(err){

                                                    Sentry.captureException(err);

                                                    setErrorMsg('Ocorreu um erro ao realizar a finalização do pedido, ao salvar a forma de pagamento em fidelidade: ' + JSON.stringify(err));
                                                    setShowModalErro(true);
                                                    setShowModalLoad(false);
                                    
                                                }
                                    
                                            });

                                            //insere forma de pagamento (dinheiro)
                                            await formasPagSelecDinheiros.map(async (item) => {
                                    
                                                try{
                                    
                                                    sequencia_pagamento += 1;
                                                    
                                                    let valorpago = 0;
                                    
                                                    formasPagSelecDinheiros.forEach((item2) => {
                                    
                                                        valorpago += parseFloat(item2[1]);
                                    
                                                    });
                                    
                                                    let quantParcelas = 1;
                                    
                                                    let data = {
                                                        idFilial: user.operador.idFilial,
                                                        idCupom: cupom.toString(),
                                                        formaPagamento: item[0].formaPagamento,
                                                        valor: valorpago,
                                                        idCliente: idClienteFidelidade == null || idClienteFidelidade == undefined || idClienteFidelidade == "" ? 0 : idClienteFidelidade,
                                                        idOperador: user.operador.codOperador,
                                                        totalCupom: total,
                                                        idECF: user.operador.numCaixa,
                                                        numeroCaixa: user.operador.numCaixa,
                                                        codigoBandeira: 0,
                                                        numeroAutorizacao: '',
                                                        idOrganizacao: user.operador.idOrganizacao,
                                                        usuarioWS: global.USER_WS,
                                                        senhaWS: global.PASS_WS,
                                                        tipoCupom: 'NF',
                                                        idVendedor: vendedorSelecionadoParam != null && vendedorSelecionadoParam != undefined ? vendedorSelecionadoParam.idVendedor : 0,
                                                        desconto: desconto,
                                                        qtdParcelaFidelidade: quantParcelas,
                                                        dataPagamento: new Date(),
                                                        troco: troco != null ? troco : 0,
                                                        totalImpostoFederal: 0,
                                                        totalImpostoEstadual: 0,
                                                        sequenciaPagamento: sequencia_pagamento,
                                                        pcOrigem: "FCXCLOUD",
                                                        ipOrigem: ipUser,
                                                        macAdressOrigem: macAddresses,
                                                        entregaLocalDadosCliente: '',
                                                        entregaLocalIdCliente: 0,
                                                        orcamentoLocal: '',
                                                        orcamentoLocalID: 0,
                                                        idClientePontuacao: 0,
                                                        docClientePontuacao: "",
                                                        pontuacaoUtilizada: 0
                                                    };

                                                    //console.log(data);
                                                    
                                                    console.log("TESTE ADD FORMAS PAGAMENTOS DINHEIRO", data);
                                                    console.log("VENDEDOR", vendedorSelecionado);
                                    
                                                    await axios.post(global.URL_BASE + global.CAMINHO_ATUALIZARFORMAPAGAMENTOCUPOM, data)
                                                    .then( (response) => {
                                    
                                                        let resultData = response.data;
                                    
                                                        //console.log("RetornoForma pagamento: ", resultData);
                                    
                                                        if(resultData.code == 0){

                                                            setShowModalConfirmarAcao(false);
                                                            setShowModalEmail(true);
                                    
                                                        }else{

                                                            setErrorMsg(resultData.msg);
                                                            setShowModalErro(true);
                                    
                                                        }
                                                        
                                                    })
                                                    .catch( (error) => {

                                                        //console.log(error);

                                                        setErrorMsg('Ocorreu um erro ao realizar a finalização do pedido: ' + JSON.stringify(error));
                                                        setShowModalErro(true);

                                                    })
                                                    .finally(() => {
                                                        setShowModalLoad(false);
                                                    });
                                    
                                                }catch(err){
                                                    
                                                    setErrorMsg('Ocorreu um erro ao realizar a finalização do pedido, ao salvar a forma de pagamento em dinheiro: ' + JSON.stringify(err));
                                                    setShowModalErro(true);
                                                    setShowModalLoad(false);
                                    
                                                }
                                    
                                            });

                                            setFuncModalConfirmacao(() => () => {});
                                            setShowModalConfirmarAcao(false);

                                        }

                                        setMsgModalConfirmarAcao("Deseja realmente finalizar esta venda ?");
                                        setFuncModalConfirmacao(() => funcFinalizarCompra);
                                        setShowModalConfirmarAcao(true);

                                        setShowModalLoad(false);
                                    }       
                                    
                                    if(vendedores != null && vendedores.length > 0 && !vendedorSelecionado){
                                        setFuncPosModalSelecionarVendedor(() => funcFinalizarCupom);
                                        setShowModalSelecionarVendedor(true);
                                    }else{
                                        funcFinalizarCupom();
                                    }
                                    
                                }}
                                >Finalizar Compra</Button>

                            </div>
                        
                        </Col>

                    </Row>

                    <Row
                    
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{marginBottom: "1%"}}

                    >

                        <Col>
                            
                            <div className="d-grid gap-2">
                                <Button
                                variant="danger"
                                onClick={() => {

                                    const funcCancelarCupom = () => {
    
                                        setShowModalLoad(true);
                                
                                        let data = {
                                            idCupom: cupom.toString(),
                                            idOrganizacao: user.operador.idOrganizacao,
                                            numeroCaixa: user.operador.numCaixa,
                                            usuarioWS: global.USER_WS,
                                            senhaWS: global.PASS_WS,
                                            idEmpresa: user.operador.idFilial,
                                            tipoCupom: "NF",
                                            pcOrigem: "FCXCLOUD",
                                            ipOrigem: ipUser,
                                            macAdressOrigem: macAddresses,
                                            idOperador: user.operador.idOperador,
                                            motivo: ""
                                        };
                                
                                        //console.log("cancelarCupom",data);
                                        //console.log("string>", JSON.stringify(data));
                                
                                        axios.post(global.URL_BASE + global.CAMINHO_CANCELARCUPOM, data)
                                        .then((response) => {
                                
                                            let resultData = response.data;
                                
                                            if(resultData.code === 0){
                                
                                                window.location.reload();
                                
                                            }else{
                                
                                                setErrorMsg(resultData.msg);
                                                setShowModalErro(true);
                                                
                                            }
                                            
                                        })
                                        .catch((error) => {
                                            Sentry.captureException(error);
                                
                                            setErrorMsg('Ocorreu um erro ao realizar o cancelamento deste cupom: ' + JSON.stringify(error));
                                            setShowModalErro(true);
                                
                                        })
                                        .finally(() => {
                                
                                            setShowModalLoad(false);
                                
                                        });
                                
                                    }

                                    if (user.operador.permiteExclusaoCupomFcx === 1) {
                                    
                                        setFuncModalAdminSenha(() => () => {
                                            setMsgModalConfirmarAcao("Deseja realmente cancelar este cupom?");
                                            setFuncModalConfirmacao(() => () => {
                                                funcCancelarCupom();
                                            });
                                            setShowModalConfirmarAcao(true);
                                        });
                                        setShowModalAdminSenha(true);

                                    } else {
                                        setErrorMsg('Operador sem permissão para cancelar cupom!');
                                        setShowModalErro(true);
                                    }

                                }}
                                >Cancelar Cupom</Button>

                            </div>
                        
                        </Col>

                    </Row>
                        

                </Container>

                :<Container style={{
                    marginTop: "1%"
                }} fluid>

                    <Row style={{justifyContent: "center", justifyItems: "center", textAlign: "center"}}>
                        <h1>Abra o caixa para iniciar novas vendas</h1>
                    </Row>
                    <Row>
                        <Col>
                            <div className="d-grid gap-2">
                                <Button
                                variant={controleAberturaFechamentoCaixa === false ? "success" : "danger"}
                                onClick={ () => {

                                    if(controleAberturaFechamentoCaixa === false){

                                        setShowModalAberturaCaixa(true);

                                    }else{

                                        //fechamento caixa

                                        if(produtos != null && produtos.length > 0){

                                            setErrorMsg('Não é possivel fechar o caixa quando houver produtos!');
                                            setShowModalErro(true);

                                        }else{

                                            const funcFechamentoCaixa = async () => {

                                                let data = {
            
                                                    idOrganizacao: user.operador.idOrganizacao,
                                                    idFechamentoAbertura: codAbertura,
                                        
                                                };
            
                                                //console.log("DATA fechamento de caixa", JSON.stringify(data));
                                                
                                                axios.post(global.URL_BASE + global.CAMINHO_FECHAMENTOCAIXA, data)
                                                .then((response) => {
                                    
                                                    let resultData = response.data;
                                        
                                                    //console.log('Fechamento de caixa Result', resultData);
                                        
                                                    if(resultData.code == 0){

                                                        setModalSucessMsg("Caixa fechado com sucesso");
                                                        setCodAbertura(0);
                                                        setControleAberturaFechamentoCaixa(false);
                                                        setShowMOdalSucess(true);
            
                                                    }else{

                                                        setErrorMsg(resultData.msg);
                                                        setShowModalErro(true);
            
                                                    }
                                                    
                                                })
                                                .catch((error) => {
                                                    Sentry.captureException(error);

                                                    setErrorMsg("Ocorreu algum erro ao tentar fechar o caixa: " + error.message);
                                                    setShowModalErro(true);

                                                })
                                                .finally(() => {
                                                    setShowModalLoad(false);
                                                });

                                                setFuncModalConfirmacao(() => null);
                                                setShowModalConfirmarAcao(false);
                                                
                                            }

                                            setFuncModalConfirmacao(funcFechamentoCaixa);
                                            setMsgModalConfirmarAcao("Deseja realmente fechar o caixa ?");
                                            setShowModalConfirmarAcao(true);

                                        }

                                    }

                                }}
                                
                                ><h5>{controleAberturaFechamentoCaixa === false ? "Abrir caixa" : "Fechar caixa"}</h5></Button>
                            </div>
                        </Col>
                    </Row>

                </Container>
            
            }

        </Container>

    );

}
